.pageWrapper {
  padding: 1.25rem;
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
}

.collectionHeader {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-bottom: 1rem;
  h1 {
    margin-top: 0;
    font-weight: var(--font-light);

    display: flex;
    align-items: flex-end;
    gap: 1rem;

    .pageTitle {
      font-size: var(--font-size-xxxxl);
    }

    .collectionName {
      font-size: var(--font-size-xxxl);
      color: var(--color-gray-400);
    }
    
  }

  .warningAlert{  
    padding:1rem;
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
    border-radius: 0.375rem;
    font-size: medium;
    font-weight: 500;    
  }
  .collectionDescription {
    padding: 1rem;
    background: #fbfbfb;
    border: 1px solid rgba(1, 10, 44, 0.08);
    border-radius: 0.375rem;
    margin-bottom: -0.2rem;
    
  }
}

.pageActions {
  display: flex;
  justify-content: space-between;
  padding-block: 1rem;
}

.buttonGroup {
  display: flex;

  button {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:not(:last-child) {
      border-right: none;
    }
  }
}

.formGroup {
  margin-top: 1rem;
}

.filePreview {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 5rem;
    width: 12.5rem;
    object-fit: cover;
    border-radius: 6px;
  }
}

.logoSwitcher {
  margin-top: 1.5rem;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  margin-bottom: 1rem;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.sectionTitle {
  margin-bottom: 0.75rem;
}

.coverImageLabel {
  margin-top: 0;
  margin-bottom: 0.75rem;
}
