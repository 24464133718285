.collections {
  max-height: 30vh;
  min-height: 30vh;
  overflow-y: auto;
  margin-bottom: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .collection {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .checkbox {
      background-color: var(--color-gray-50);
      padding: 1rem;
      border-radius: 0.25rem;
      flex: 1 1 0;
      max-width: 50%;

      span {
        &:last-child {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .assetsCount {
      text-transform: uppercase;
      font-size: 0.875rem;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 1rem;
}

.search {
  margin-bottom: 1rem;
}
