.Table {
  border-spacing: 0 2px;
  width: 100%;
  min-width: 900px;

  thead {
    th {
      color: #010a2c;
      opacity: 0.5;
      font-family: Ubuntu;
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size-s);
      line-height: var(--line-height-xs);
      font-weight: var(--font-normal);
      text-transform: uppercase;
      text-align: left;
      letter-spacing: 1px;

      padding: 0.5rem 1.625rem;
    }
  }

  tbody {
    tr {
      background-color: var(--color-gray-50);

      td {
        padding: 1.0625rem 0.3125rem;

        text-align: center;

        &:first-child {
          padding-left: 0.9375rem;
        }

        &:last-child {
          padding-right: 0.9375rem;
        }
      }
    }
  }
}

.responsiveTable {
  overflow-x: auto;
}
