.pageWrapper {
  flex: 1 0 auto;

  padding: 1.25rem;
}

.container {
  padding-top: 1.5rem;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    max-width: 75vw;
  }

  @media screen and (min-width: 1280px) {
    max-width: 50vw;
  }
}

.userName {
  display: flex;

  gap: 1.5rem;

  > * {
    flex: 1 0 auto;
  }
}

.actions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.error {
  font-size: var(--font-size-s);
  color: var(--color-red-400);
}

.groupButtons {
  display: flex;
  gap: 1rem;
}

.foldableContainer {
  margin-bottom: 30px;

  h4 {
    font-size: 21px;
    display: flex;
    user-select: none;
    cursor: pointer;
    font-weight: 500;

    svg {
      font-size: 24px;
      margin-right: 10px;
      color: #2e53e3;
    }
  }
}
