.Select {
  border-radius: 0.375rem;
  border: 1px solid rgba(1, 10, 44, 0.08);
  background: #ffffff;
  padding: 0.625rem 1rem;
  margin-top: 20px;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE4IDEwIiBmaWxsPSJub25lIj48cGF0aCBkPSJNMTYuNSAxTDkgOC41TDEuNSAxIiBzdHJva2U9IiMwNjMzREUiIHN0cm9rZS13aWR0aD0iMS40IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 98%;

  outline: none;

}
.label {
  margin-top: 10px;
  margin-bottom: 10px;
}
