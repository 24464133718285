.SearchForm {
}

.schemaFormContainer {
  .formButtonsContainer {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;

    button {
      flex: 1 0 0;
    }
  }
}

.formContent {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.schemaFormTitle {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
}

.formGroup {
  input {
    border-radius: 0.375rem;
    border: 1px solid rgba(1, 10, 44, 0.1);
    padding: 0.5625rem 1rem;
    font-size: var(--font-size-s);
    font-weight: var(--font-normal);
    color: #010a2c;
    background-color: white;
    width: 100%;
    &:focus-visible {
      outline: none;
      box-shadow: var(--shadow-1);
    }
    margin-top: 0.625rem;
  }

  .formLabel {
    font-size: var(--font-size-s);
    font-weight: var(--font-normal);
    color: #010a2c;
    opacity: 0.5;
    // text-transform: uppercase; (To remove the uppercase bug #414)
    display: flex;
    gap: 0.5rem;
    align-items: center;

    button {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
  }

  .formDescription {
    font-size: var(--font-size-s);
    font-weight: var(--font-normal);
    p {
      margin: 0;
      margin-top: 0.5rem;
      color: #010a2c;
      opacity: 0.5;
    }

    margin-bottom: 1rem;
  }

  .formHelp {
    display: flex;

    padding: 0.875rem;
    border: 2px solid var(--color-gray-100);
    border-radius: 0.5rem;
    gap: 1rem;

    margin-top: 1rem;

    p {
      margin: 0;
      font-size: 1rem;
    }
  }

  .formErrors {
    color: var(--color-red-400);
    font-size: var(--font-size-s);
    ul {
      margin: 0;
      padding: 0;
      li {
        font-weight: var(--font-medium);
        list-style: none;
      }
    }
    margin-bottom: 1rem;
  }

  input[type='checkbox'] {
    margin: 0 0.2rem 0 0;
    transform: scale(0.9);

    + span {
      font-size: var(--font-size-s);
      font-weight: var(--font-normal);
      color: #010a2c;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;
      white-space: nowrap; // Prevent wrapping
    }
  }

  label,
  p {
    font-size: var(--font-size-s);
    font-weight: var(--font-normal);
    color: #010a2c;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
  }
}

.addFieldButton {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background: var(--color-prime-100);
  color: #0633de;
  font-size: 0.6rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
}

.fieldFormButtons {
  padding: 7px;
}
