.Loader {
  height: 32px;
  width: 32px;
  animation: loaderParent 4.8s linear infinite;
  display: inline-block;

  &.small {
    height: 12px;
    width: 12px;

    span {
      width: 12px;
      height: 12px;
      clip: rect(0, 12px, 12px, 6px);

      &:after {
        width: 12px;
        height: 12px;
        border-width: 1px;
        clip: rect(0, 12px, 12px, 6px);
      }
    }
  }

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    clip: rect(0, 32px, 32px, 16px);
    animation: loaderSpan 1.2s linear infinite;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 32px;
      width: 32px;
      clip: rect(0, 32px, 32px, 16px);
      border: 3px solid;
      border-color: inherit;
      border-radius: 50%;
      animation: loaderAfter 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    }
  }
}

@keyframes loaderParent {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loaderSpan {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(220deg);
  }
}

@keyframes loaderAfter {
  0% {
    transform: rotate(-140deg);
  }
  50% {
    transform: rotate(-160deg);
  }
  100% {
    transform: rotate(140deg);
  }
}
