@mixin slider-track {
  input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    display: block;
    position: relative;
    z-index: 2;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range'] {
    position: relative;
    overflow: hidden;
    height: 5px;
    cursor: pointer;
    border-radius: 0; /* iOS */
  }

  ::-webkit-slider-runnable-track {
    background: transparent;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0;
    height: 5px;
    background: #fff;
    box-shadow: -100vw 0 0 100vw #0633de;
  }

  ::-moz-range-track {
    height: 5px;
    background: #ddd;
  }

  ::-moz-range-thumb {
    background: #fff;
    height: 5px;
    width: 0;
    box-shadow: -100vw 0 0 100vw #0633de;
    box-sizing: border-box;
  }
}

.FlatButton {
  background-color: transparent;
  border-radius: 3px;
  margin: 0;
  padding: 1px 4px;
  display: flex;
  align-items: center;
  border: none;
  transition: 0.2s ease;
  cursor: pointer;

  > span {
    &.alticon {
      color: #0633de;
    }

    &:first-child {
      font-size: 22px;
    }

    + span {
      margin-left: 10px;
    }
  }

  &:hover {
    background-color: #eee;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: default;
  }
}

.container {
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.Loader {
  margin: auto;
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(#010a2c, 0.3);
  backdrop-filter: blur(10px);
  display: flex;

  .wrapper {
    background-color: #fff;
    border: 1px solid rgba(1, 10, 44, 0.1);
    border-radius: 6px;
    margin: auto;
    width: 92vw;
    height: 90vh;
    max-width: 1360px;
    display: flex;
    flex-direction: column;
  }
}

.header {
  flex: 0 0 auto;
  display: flex;
  padding: 10px;
  background-color: #fbfbfb;
  box-shadow: 0 1px 0 rgba(1, 10, 44, 0.08);
  z-index: 2;

  .actions {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    font-size: 16px;

    > div {
      border-left: 1px solid rgba(#010a2c, 0.12);
      padding-left: 10px;
      margin-left: 10px;

      &.push {
        margin-left: auto;
        display: flex;
      }

      &:last-child {
        margin-right: 10px;
      }
    }
  }
  .controls {
    flex: 0 1 384px;
    max-width: 384px;
    width: 384px;
    display: flex;
    align-items: center;

    > div {
      border-left: 1px solid rgba(#010a2c, 0.12);
      padding-left: 10px;
      margin-left: 10px;
      display: flex;

      &.title {
        font-size: 18px;
        margin-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.push {
        margin-left: auto;
      }
    }
  }
}

.content {
  flex: 1 1 auto;
  display: flex;
  min-height: 0;

  .preview {
    flex: 1 1 auto;
    background-color: #f5f5f5;
    box-shadow: inset -1px 0 0 rgba(1, 10, 44, 0.08);
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;

    img > #original_img_holder {
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    video {
      display: block;
    }

    .player {
      margin: auto;
      width: 98%;
      max-width: 860px;
      background-color: #ffffff;

      .videoCover {
        img {
          width: 100%;
          object-fit: cover;
          height: 420px;
        }
      }

      .seeker {
        position: relative;

        @include slider-track;

        .loaded {
          position: absolute;
          top: 0;
          left: 0;
          height: 5px;
          background-color: rgba(#0633de, 0.1);
        }
      }

      .controls {
        padding: 10px;
        display: flex;
        align-items: center;

        button {
          cursor: pointer;
          background-color: transparent;
          border: none;
        }

        button:hover {
          color: var(--color-prime-400);
        }

        .play {
          font-size: 28px;
        }
        span {
          font-size: 14px;
          margin-left: 10px;
          margin-right: 10px;
        }
        .mute {
          margin-left: auto;
          font-size: 16px;
        }
        .volume {
          width: 80px;
          background-color: #eee;

          @include slider-track;
        }
      }
    }
  }

  .details {
    flex: 0 0 393px;
    max-width: 393px;
    min-width: 393px;
    width: 393px;
    display: flex;
    flex-direction: column;

    .smallParagraph {
      font-size: 13px;
      line-height: 22px;
      color: #010a2c;
    }

    .metaField {
      margin-bottom: 20px;

      p {
        // text-transform: uppercase;
        opacity: 0.5;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
      }

      div {
        font-size: 14px;
        background: #fbfbfb;
        border: 1px solid rgba(1, 10, 44, 0.1);
        border-radius: 6px;
        padding: 12px 20px;
        overflow-wrap: break-word;
      }
    }

    .tabs {
      flex: 0 0 auto;
      padding: 20px 0 20px 20px;

      button {
        font-size: 16px;
        line-hegiht: 26px;
        color: #010a2c;
        cursor: pointer;
        padding: 7px 12px;
        transition: 0.2s ease;
        border-radius: 6px;
        border: none;
        background-color: transparent;
        margin-right: 1px;

        &:hover,
        &.active {
          color: #0633de;
          background-color: #e7ebfc;
        }
      }
    }
    .scroller {
      flex: 1 1 auto;
      overflow-x: hidden;
      overflow-y: auto;
      overscroll-behavior: contain;
      padding: 0 20px 10px 20px;
    }
    .tags {
      flex: 0 0 auto;
      padding: 20px 10px 12px 20px;
      display: flex;
      flex-wrap: wrap;

      span {
        display: inline-flex;
        padding: 6px 8px;
        background-color: #fbfbfb;
        border: 1px solid rgba(1, 10, 44, 0.08);
        border-radius: 3px;
        font-size: 14px;
        margin: 0 8px 8px 0;
      }
    }
  }
}

.tabButtons {
  display: flex;
  flex-direction: column;
  padding: 10px;

  button {
    border: none;
    border-radius: 6px;
    background: #fff;
    font-size: 22px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: 0.2s ease;
    height: 32px;
    line-height: 32px;

    &.active,
    &:hover {
      background: #e7ebfc;
    }
  }
}

.editor {
  width: 100%;
}

.metadataRow {
  display: flex;
  background: #fbfbfb;
  border-top: 1px solid rgba(#010a2c, 0.08);
  align-items: center;
  padding: 10px;
  font-size: 16px;

  > div:first-child {
    // text-transform: uppercase;
    color: #010a2c;
    font-size: 12px;
    min-width: 30%;
    margin-right: 10px;
  }

  .metadataRowButtons {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  button {
    margin-right: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 18px;
    border-radius: 3px;
    transition: 0.2s ease;

    &:hover {
      background: rgba(#010a2c, 0.08);
    }

    &[disabled] {
      opacity: 0.3;
      cursor: default;
    }
  }
}

.metadataWrapper {
  background: #fbfbfb;
  border-radius: 8px;
  padding: 16px;

  .title {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    color: #010a2c;

    .edit {
      font-size: 24px;
      color: #2e53e3;
      border: none;
      background: transparent;
      padding: 4px;
      border-radius: 3px;
      transition: 0.2s ease;
      cursor: pointer;

      &:hover {
        background: #e7ebfc;
      }
    }
  }
}

.AssetUpdatedConfirmationDialogContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.25);
  z-index: 1337;

  .AssetUpdatedConfirmationDialog {
    padding: 1rem 1.5rem;

    background: var(--color-gray-50);
    border-radius: 0.25rem;
    box-shadow: var(--shadow-3);

    width: 25rem;

    .conversionForm {
      button {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
      }
    }

    .AssetDialogButtons {
      display: flex;
      gap: 1rem;
      flex-direction: column;
    }
  }
}

.metadataInitContainer {
  .metadataInitForm {
    display: flex;
    flex-direction: column;

    gap: 1.25rem;

    .separator {
      display: flex;
      align-items: center;
      text-align: center;
    }

    .separator::before,
    .separator::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #000;
    }

    .separator:not(:empty)::before {
      margin-right: 0.25em;
    }

    .separator:not(:empty)::after {
      margin-left: 0.25em;
    }

    .selectionContainer {
      display: flex;
      flex-direction: column;

      gap: 0.5rem;

      p {
        margin: 0;
      }
    }
  }
}

.freeFormDataContainer,
.metadataFreeFormDataInput {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.error {
  color: var(--color-red-400);
  font-size: var(--font-size-s);
  margin: 0;
}

.metadataFreeFormFieldName {
  display: flex;

  margin: 0;

  > * {
    flex: 1 0 auto;
  }
}

.metaFieldEditable {
  div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      // text-transform: uppercase;
      opacity: 0.5;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
    }
  }

  div:last-child {
    font-size: 14px;
    background: #fbfbfb;
    border: 1px solid rgba(1, 10, 44, 0.1);
    border-radius: 6px;
    padding: 12px 20px;
    overflow-wrap: break-word;
  }
}

.fullWidthButton {
  width: 100%;
}

.formButtonsContainer {
  display: flex;

  gap: 1rem;

  > * {
    flex: 1 0 auto;
  }
}

.captionsContainer {
  position: absolute;
  width: 14rem;
  bottom: 0.25rem;
  right: 0.5rem;
  background: white;
  z-index: 1337;
  height: 50%;
  overflow: scroll;
  min-height: 10rem;
  box-shadow: var(--shadow-2);
}

.showCaptions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.showCaptions:hover {
  color: var(--color-prime-400);
}

.caption {
  background: white;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 0.5rem 0.15rem;
  font-size: var(--font-size-s);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  svg {
    position: absolute;
    right: 1rem;
  }
}

.caption:hover {
  background: #eee;
}

.subtitleEditor {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .subtitleEditorTextareaContainer {
    display: flex;
    height: 100%;
    position: relative;

    .textareaLine {
      outline: none;
      background: whitesmoke;
      width: 5.5rem;
      padding: 1rem 0.5rem;
      text-align: right;
      resize: none;
      height: 100%;
      overflow: hidden;
      border: none;
      border-right: 2px solid #edeef0;
      border-bottom: 2px solid #edeef0;
    }

    .textareaEditor {
      resize: none;
      width: 100%;
      height: 100%;
      border: none;
      border-right: 2px solid #edeef0;
      outline: none;
      padding: 1rem 0.5rem;
    }

    .editorErrors {
      position: absolute;
      right: 1rem;
      top: 1rem;
      background: var(--color-red-100);
      width: 30%;
      border: 1px solid var(--color-red-400);
      border-radius: 6px;
      padding: 0.5rem 1rem;
      max-height: 95%;
      overflow: auto;

      ul {
        font-size: var(--font-size-s);
        color: var(--color-red-400);
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .subtitleEditorControls {
    display: flex;
    padding: 0.5rem 1rem;
    gap: 1rem;
    justify-content: flex-end;

    > p {
      flex: 1 0 auto;
    }
  }
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.processingAsset {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background: var(--color-prime-100);
  color: #0633de;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  z-index: 1337;
}
