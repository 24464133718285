.LayoutView {
  .shimmer {
    margin-bottom: 10px;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  flex: 1 0 auto;

  svg {
    font-size: 4rem;
  }

  p {
    font-size: var(--font-size-xxxl);
  }
}
