.SaveChangesBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem;
  border-top: solid 1px #010a2c1f;
  border-bottom: solid 1px #010a2c1f;

  .unsavedChanges {
    font-weight: var(--font-light);
    font-size: var(--font-size-s);
    line-height: var(--line-height-xs);
    color: #010a2c;
    display: flex;
    gap: 0.625rem;
    align-items: center;
  }

  .buttonGroup {
    display: flex;
    gap: 0.625rem;
  }
}
