.pageWrapper {
  margin-top: 0;
}

.container {
  padding: 1.25rem;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.25rem;

  .sidebar {
    grid-column: span 3 / span 3;

    nav {
      top: 1.25rem;
      position: sticky;
      display: flex;
      flex-direction: column;
      gap: 2px;
      a {
        padding: 0.625rem 0.9375rem;
        background-color: var(--color-gray-50);
        border-radius: 6px;
        color: #010a2c;
        font-weight: var(--font-light);
        font-size: 1rem;
        text-decoration: none;
        &:hover,
        &:focus,
        &.active {
          background-color: var(--color-prime-100);
          font-weight: var(--font-medium);
          color: var(--color-prime-400);
        }
      }
    }
  }

  .content {
    grid-column: span 9 / span 9;
    padding-right: 8.75rem;

    @media screen and (max-width: 1024px) {
      padding-right: 4rem;
    }
  }
}

.securitySectionDescription {
  font-size: var(--font-size-s);
  font-weight: var(--font-normal);
  color: #010a2c;
  opacity: 0.5;
  margin-bottom: 0;
}

.inlineButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clearMargin {
  margin: 0;
}
