.pageWrapper {
  // display: flex;
  flex: 1 0 auto;
}

.pageTitle {
  padding: 1.25rem;
}
.filtersContent {
  flex: 0 0 390px;
  width: 390px;
  padding: 20px 20px 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  .rangeValues {
    // display: flex;
    // align-items: center;

    > div {
      // width: 50%;
    }

    input {
      // padding-right: 2px;
      // padding-left: 2px;
    }
  }
}
.pageContainer {
  display: flex;
}
.foldableContainer {
  margin-bottom: 30px;

  h4 {
    font-size: 17px;
    display: flex;
    user-select: none;
    cursor: pointer;
    font-weight: 500;
    
    svg {
      font-size: 20px;
      margin-right: 10px;
      color: #2e53e3;
    
    }
  }

  .filterChildren {
    padding-left: 34px;

    > * {
      margin-bottom: 14px;
    }
  }
}

.pageContent {
  flex: 1 1 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
 

  display: flex;
  flex-direction: column;

  .shimmer {
    margin-bottom: 10px;
  }
}

.filtersWrapper {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;

  .tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
      margin-right: 10px;
    }
  }

  .actions {
    display: flex;

    > * {
      margin-left: 10px;
    }
  }
}

.pagerWrapper {
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBar {
  margin-inline: -1.25rem;
  padding-left:1.25rem;
  padding-right: 1.25rem;

}

.pageActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 1rem;
}

.Select {
  margin-top: 0px;
}

.dropD {
  
  margin-bottom: 30px;
  max-width: 100%;
  width: 330px;
  max-height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: -40px;

}

.dropD > button {
  justify-content: space-between;
}

.formContainer {
  min-height: 300px;
  max-height: 300px; 
  overflow-y: scroll;
  transition: max-height 0.3s ease;
}

.formFaceContainer {
  min-height: 200px;
  max-height: 200px; 
  overflow-y: scroll;
  transition: max-height 0.3s ease;
}

.formNewContainer {
  min-height: 300px;
}

.formFaceNewContainer {
  min-height: 100px;
  overflow-y: hidden;
  max-width: 330px;
}


.formContainer::-webkit-scrollbar {
  width: 10px; 
}



.formContainer::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 6px; 
}


.formContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.formFaceContainer::-webkit-scrollbar {
  width: 10px; 
}



.formFaceContainer::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 6px; 
}


.formFaceContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.formButtonsContainer {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;

  button {
    flex: 1 0 0;
  }

  margin-bottom: 200px;
}

.formFaceButtonsContainer {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;

  button {
    flex: 1 0 0;
  }

  // margin-bottom: 10px;
}


.metadataInitContainer {
  max-height: 200px;
  overflow-y: scroll;
  
  .metadataInitForm {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
   
   

    .separator {
      display: flex;
      align-items: center;
      text-align: center;
    }

    .separator::before,
    .separator::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #000;
    }

    .separator:not(:empty)::before {
      margin-right: 0.25em;
    }

    .separator:not(:empty)::after {
      margin-left: 0.25em;
    }

    .selectionContainer {
      display: flex;
      flex-direction: column;

      gap: 0.5rem;

      p {
        margin: 0;
      }
    }
  }
}

.metadataInitContainer::-webkit-scrollbar {
  width: 10px; 
}



.metadataInitContainer::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 6px; 
}


.metadataInitContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.freeFormDataContainer,
.metadataFreeFormDataInput {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
  
}

.error {
  color: var(--color-red-400);
  font-size: var(--font-size-s);
  margin: 0;
}

.metadataFreeFormFieldName {
  display: flex;

  margin: 0;

  > * {
    flex: 1 0 auto;
  }
}

.metaFieldEditable {
  div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      // text-transform: uppercase;
      opacity: 0.5;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
    }
  }

  div:last-child {
    font-size: 14px;
    background: #fbfbfb;
    border: 1px solid rgba(1, 10, 44, 0.1);
    border-radius: 6px;
    padding: 12px 20px;
    overflow-wrap: break-word;
  }
}

.fullWidthButton {
  width: 100%;
  max-width: 330px;
}

.formButtonsContainer {
  display: flex;

  gap: 1rem;

  > * {
    flex: 1 0 auto;
  }
}