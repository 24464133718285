$track-color: linear-gradient(to right, #2e53e3 0%, #2e53e3 50%, #e7ebfc 50%, #e7ebfc 100%) !default;
$thumb-color: #2e53e3 !default;

$thumb-radius: 20px !default;
$thumb-height: 16px !default;
$thumb-width: 16px !default;
$thumb-border-width: 1px !default;
$thumb-border-color: #e7ebfc !default;

$track-width: 100% !default;
$track-height: 4px !default;
$track-radius: 4px !default;

@mixin track {
  cursor: pointer;
  height: $track-height;
  transition: all 0.2s ease;
  width: $track-width;
}

@mixin thumb {
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  cursor: pointer;
  height: $thumb-height;
  width: $thumb-width;
}

[type='range'] {
  -webkit-appearance: none;
  margin: $thumb-height / 2 0;
  background: $track-color;
  border-radius: $track-radius;
  @include track;

  &:focus {
    outline: 0;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: 0;
  }
}

.sliderContainer {
  display: flex;
  gap: 1rem;
  span {
    font-size: var(--font-size-l);
    color: #010a2c;
  }
  input {
    width: 100%;
  }
}
