.InfoSection {
  display: flex;

  padding: 0.875rem;
  border: 2px solid var(--color-gray-100);
  border-radius: 0.5rem;
  gap: 1rem;

  margin-top: 1rem;
  margin-bottom: 1rem;

  p {
    margin: 0;
    font-size: 1rem;
  }

  .icon {
    flex: 1 0 auto;
  }
}
