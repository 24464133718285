.SearchBar {
  background-color: var(--color-gray-50);
  box-shadow: var(--shadow-1);
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem;

  .searchInput {
    flex: 1 0 auto;
    position: relative;
    display: flex;

    div:first-child {
      flex: 1 0 auto;

      input[data-has-search-button='true'] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    > button {
      background-color: var(--color-prime-400);
      color: white;
      border: 1px solid var(--color-gray-dark);
      border-top-right-radius: 4.5rem;
      border-bottom-right-radius: 4.5rem;

      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 2rem;
      padding-left: 1.5rem;

      cursor: pointer;

      &:hover {
        background-color: var(--color-prime-600);
      }

      &:active {
        background-color: var(--color-prime-400);
        box-shadow: 0 0 0.5rem var(--color-prime-400);
      }

      svg {
        font-size: 1.625rem;
      }
    }
  }

  .selectionInfo {
    display: flex;
    align-items: center;
    font-size: var(--font-size-s);

    > span {
      &:first-of-type {
        color: var(--color-prime-400);
        background-color: var(--color-prime-100);
        line-height: 1.5rem;
        min-width: 1.5rem;
        text-align: center;
        border-radius: 0.33rem;
        margin-right: 0.5rem;
      }

      + span {
        font-size: var(--font-size-s);
      }
    }

    .refetchButton {
      &::after {
        border-right: 2px solid var(--color-gray-200);
        content: '';
        height: 2rem;
        margin-right: 0.75rem;
        margin-left: 0.25rem;
      }
    }
  }
}

.basketDropdown,
.trashDropdown {
  > div {
    width: 20rem;
    span {
      font-size: var(--font-size-s);
    }
  }
}

.sortDropdown {
  div[role='menu'] {
    width: auto;
  }
}

.faceIcon {
  position: absolute;
  top: 20%;
  font-size: 30px;
  right: 0.5rem;
  z-index: 3;
  cursor: pointer;
}

.faceIconTooltip {
  position: absolute;
  top: 20%;
  right: 3rem;
  z-index: 3;
}

.TooltipSlider {
  position: absolute;
  z-index: 3;
}

.inputImgHolder {
  position: absolute;
  z-index: 6;
  right: 3rem; /* Aligns with .faceIcon */
  top: 10%; /* Aligns vertically with .faceIcon */
  width: 40px;
  height: 40px;
}

.inputImgStyle{
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
  transform: scaleX(-1);
  cursor: pointer;
}

.inputImgCloseIcon {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  z-index: 3;
}
