.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .sticky {
    position: sticky;
    top: 0px;
    z-index: 10;
    background-color: #fff;
  }
}
