.SearchFiltersSidebar {
  padding: 1.25rem 1.25rem 1.875rem;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  flex: 0 0 320px;

  .foldableContainer {
    margin-bottom: 30px;

    h4 {
      font-size: 21px;
      display: flex;
      user-select: none;
      cursor: pointer;
      font-weight: 500;

      svg {
        font-size: 24px;
        margin-right: 10px;
        color: #2e53e3;
      }
    }

    .filterChildren {
      padding-left: 34px;

      > * {
        margin-bottom: 14px;
      }
    }
  }

  .applyFiltersButton {
    width: 100%;
  }
}
