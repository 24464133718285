.Dropdown {
  position: relative;
  display: inline-block;
  text-align: left;

  &.menu {
    transition: 0.3s;

    > button {
      background-color: transparent;
      border: none;
      transition: 0.3s;
      border-radius: 50%;
      padding: 0;
      width: 1.5rem;
      height: 1.5rem;
      justify-content: center;

      svg {
        margin-left: 0;
      }

      &:focus {
        background-color: var(--color-prime-100);
        box-shadow: none;

        svg {
          color: var(--color-prime-600);
        }
      }
    }

    &:hover {
      > button {
        background-color: var(--color-prime-200);
      }

      svg {
        color: var(--color-prime-700);
      }
    }
  }

  &.filter {
    > button {
      border: none;
      background-color: transparent;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.anchorRight {
    > div {
      left: auto;
      right: 0;

      > span {
        justify-content: flex-end;
      }
    }
  }

  > button {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 0.85rem;
    font-size: 16px;
    color: var(--color-gray-600);
    cursor: pointer;
    transition: 0.2s ease;
    min-height: 42px;
    background-color: #fbfbfb;
    border: 1px solid rgba(1, 10, 44, 0.12);
    box-sizing: border-box;
    border-radius: 4.5rem;

    &:focus {
      outline: none;
      box-shadow: var(--shadow-1);
    }

    svg {
      margin-left: 0.75rem;
      color: #111111;
    }

    &[disabled] {
      cursor: default;
      opacity: 0.7;
    }
  }

  > div {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 160px;
    max-width: 320px;
    z-index: 49;
    margin-top: 0.25rem;
    border-radius: 4px;
    background-color: #fff;
    max-height: 420px;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-10px);
    transition: 0.25s cubic-bezier(0.52, -0.68, 0.38, 1.6);
    user-select: none;
    border: 1px solid rgba(1, 10, 44, 0.08);
    box-shadow: 0px 4px 20px rgba(48, 55, 83, 0.1);
    padding: 0 16px 16px;
    
    &.reduceSize {
      max-height: 320px;
    }
    &.open {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translateY(0);
      // max-height: 200px;

      &.withTitle {
        padding-bottom: 0.5rem;
      }
    }

    &.directionUp {
      bottom: 100%;
      top: auto;
      margin-top: 0;
      margin-bottom: 0.25rem;
    }

    > span {
      color: var(--color-gray-400);
      display: flex;
      align-items: center;
      padding: 20px 10px 20px;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 21px;
      position: relative;
      transition: 0.2s ease;
      cursor: pointer;
    

      &.disabled {
        color: var(--color-gray-200);
        cursor: initial;

        &:hover {
          color: var(--color-gray-200);
        }

        &:active {
          text-decoration: none;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-gray-200);

        &.noborder {
          border-bottom-color: transparent;
        }
      }

      &.noborder {
        padding: 10px;
        margin-bottom: 0;
      }

      &.isSort {
        white-space: nowrap;
      }

      &.title {
        text-transform: uppercase;
        cursor: default;
        color: var(--color-gray-300);
        border-bottom: none;
        padding-top: 2rem;
        padding-bottom: 0;
        font-size: var(--font-size-s);

        &:hover {
          color: var(--color-gray-300);
        }

        &:active {
          text-decoration: none;
        }
      }

      &:hover {
        color: var(--color-prime-600);

        .icon {
          color: var(--color-prime-600);
        }
      }

      &:active {
        color: var(--color-prime-400);
        text-decoration: underline;
      }

      &.selected {
        color: var(--color-prime-400);
        font-weight: var(--font-medium);
        text-decoration: underline;
      }

      svg {
        margin-right: 0.75rem;
      }

      .check {
        color: var(--color-green-400);
      }

      .icon {
        color: var(--color-gray-200);
        font-size: var(--font-size-base);
        transition: 0.2s ease;
      }

      .iconSelected {
        color: var(--color-prime-400);
        font-size: var(--font-size-base);
      }
    }
  }
}

.clickable {
  cursor: pointer !important;
}
