.newDropdown {
    position: relative;
    display: inline-block;
    text-align: left;
    width: auto;
  
    .dropdownToggle {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.5rem 0.85rem;
      font-size: 16px;
      color: var(--color-gray-600);
      cursor: pointer;
      transition: 0.2s ease;
      min-height: 42px;
      background-color: #fbfbfb;
      border: 1px solid rgba(1, 10, 44, 0.12);
      box-sizing: border-box;
      border-radius: 4px;
  
      &:focus {
        outline: none;
        box-shadow: var(--shadow-1);
      }
  
      .arrow {
        margin-left: 0.75rem;
        color: #111111;
      }
  
      &[disabled] {
        cursor: default;
        opacity: 0.7;
      }
    }
  
    .dropdownMenu {
      position: absolute;
      top: 100%;
      left: 0;
      width: auto; 
      min-width: 100%; 
      max-width: none; 
      
      overflow-y: auto;
      overflow-x: hidden;
      white-space: nowrap;
      z-index: 49;
      margin-top: 0.25rem;
      border-radius: 4px;
      background-color: #fff;
      max-height: 420px;
      
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translateY(-10px);
      transition: 0.25s cubic-bezier(0.52, -0.68, 0.38, 1.6);
      user-select: none;
      border: 1px solid rgba(1, 10, 44, 0.08);
      box-shadow: 0px 4px 20px rgba(48, 55, 83, 0.1);
      padding: 0 16px 16px;
  
      &.open {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translateY(0);
      }
  
      .dropdownItem {
        color: var(--color-gray-400);
        display: flex;
        align-items: center;
        padding: 10px 10px; // Reduced padding to decrease gap
        font-size: 16px;
        line-height: 21px;
        position: relative;
        transition: 0.2s ease;
        white-space: nowrap;
        cursor: pointer;
  
        &:not(:last-child) {
          border-bottom: 1px solid var(--color-gray-200);
        }
  
        &:hover {
          color: var(--color-prime-600);
        }
  
        &:active {
          color: var(--color-prime-400);
          text-decoration: underline;
        }
  
        &.selected {
          color: var(--color-prime-400);
          font-weight: var(--font-medium);
          text-decoration: underline;
        }
  
        &.disabled {
          color: var(--color-gray-200);
          cursor: not-allowed;
  
          &:hover {
            color: var(--color-gray-200);
          }
  
          &:active {
            text-decoration: none;
          }
        }
      }
    }
  }