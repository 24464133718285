.modal_header {
  font-weight: 500;
  margin-inline: 10px;
  color: var(--color-prime-400);
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.textarea_sty {
  flex: 1;
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 20px;
  font-size: 15px;
  outline: none;
  height: 100%;
  resize: none;
  width: 100%;
}

.error_sty {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  background-color: #dc3545;
  text-align: center;
  border-radius: 10px;
  display: block;
  margin: 10px;
  margin-bottom: 0px;
  min-height: 35px;
}

.option_header {
  margin: 10px;
  margin-left: 5px;
}

.option_holder {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px;
}

.content_input {
  border-radius: 5px !important;
  width: 150px !important;
  margin-left: 5px;
}

.Select {
  padding: 0.85rem !important;
  margin-top: -15px !important;
}

.btnStyle {
  min-width: 150px !important;
  max-width: 150px !important;
  align-self: flex-end;
}
