$opacity: 0.25;
$dialogWidth: 30vw;
$shadow: var(--shadow-3);
$borderRadius: 6px;

.ConfirmDialog {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7331;

  .backdrop {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, $opacity);
  }

  .dialog {
    padding: 1rem;
    background: white;
    border-radius: $borderRadius;
    width: $dialogWidth;
    box-shadow: $shadow;
    z-index: 1;

    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem 1.5rem;

      button {
        flex: 1 0 0;
      }
    }
  }
}
