:root {
  /* COLORS */
  --bg: #ffffff;
  --color-prime-100: #e7ebfc;
  --color-prime-200: #8f9edb;
  --color-prime-300: #6a86f5;
  --color-prime-400: #2e53e3;
  --color-prime-500: #002feb;
  --color-prime-600: #0028c9;
  --color-prime-700: #0020a1;
  --color-prime-800: #001a80;
  --color-alt-100: #edf7ff;
  --color-alt-200: #84a5cc;
  --color-alt-300: #3a6fad;
  --color-alt-400: #094b99;
  --color-alt-500: #073c7a;
  --color-alt-600: #052d5c;
  --color-alt-700: #03162e;
  --color-alt-800: #01070f;
  --color-gray-50: #fbfbfb;
  --color-gray-100: #eaeae9;
  --color-gray-200: #d3d3d1;
  --color-gray-300: #ababa7;
  --color-gray-400: #6c6d64;
  --color-gray-500: #4c4c46;
  --color-gray-600: #363732;
  --color-gray-700: #20211e;
  --color-gray-800: #0b0b0a;
  --color-red-100: #f9ecec;
  --color-red-200: #f18989;
  --color-red-400: #eb5757;
  --color-red-600: #a53d3d;
  --color-red-800: #461a1a;
  --color-green-100: #edf9ec;
  --color-green-200: #64b687;
  --color-green-400: #219653;
  --color-green-600: #17693a;
  --color-green-800: #114b2a;

  /* FONT WEIGHTS */
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-bold: 700;

  /* FONT SIZES */
  --font-size-base: 18px;
  --font-size-xxs: 0.625rem;
  --font-size-xs: 0.75rem;
  --font-size-s: 0.875rem;
  --font-size-l: 1.15rem;
  --font-size-xl: 1.33rem;
  --font-size-xxl: 1.5rem;
  --font-size-xxxl: 2rem;
  --font-size-xxxxl: 2.25rem;

  /* LINE HEIGHTS / LEADINGS */
  --line-height-base: 1.5;
  --line-height-xxs: 1;
  --line-height-xs: 1;
  --line-height-s: 1.25;
  --line-height-l: 1.75;
  --line-height-xl: 1.75;
  --line-height-xxl: 2;
  --line-height-xxxl: 2.5;
  --line-height-xxxxl: 2.75;

  /* SPACINGS */
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 1rem;
  --spacing-4: 1.5rem;
  --spacing-5: 2rem;
  --spacing-6: 2.5rem;
  --spacing-7: 3rem;
  --spacing-8: 3.5rem;
  --spacing-9: 4rem;

  /* BOX SHADOWS */
  --shadow-1: 0px 1px 2px rgba(0, 0, 0, 0.1);
  --shadow-2: 0px 4px 4px rgba(132, 165, 204, 0.15), 0px 0px 8px rgba(132, 165, 204, 0.5);
  --shadow-3: 0px 4px 4px rgba(132, 165, 204, 0.15), 0px 0px 16px rgba(132, 165, 204, 0.5);
  --shadow-4: 0px 4px 4px rgba(132, 165, 204, 0.15), 0px 0px 24px rgba(132, 165, 204, 0.5);
  --shadow-5: 0px 4px 4px rgba(132, 165, 204, 0.15), 0px 0px 32px rgba(132, 165, 204, 0.5);
}
