.Tag {
	display: inline-flex;
	padding: 9px 18px;
	background-color: #fbfbfb;
	border: 1px solid rgba(1, 10, 44, 0.08);
	border-radius: 3px;

	&.closeable {
		padding-right: 12px;
	}

	span {
		font-size: 20px;
		margin-left: 10px;
		transition: 0.2s ease;
		cursor: pointer;

		&:hover {
			transform: rotate(90deg);
		}
	}
}
