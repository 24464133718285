.Checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  // padding: 0.25rem;
  user-select: none;

  .icon {
    font-size: var(--font-size-xl);
    color: var(--color-gray-600);

    &:not(:only-child) {
      margin-right: 1rem;
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.7;
  }

  &.checked {
    .icon {
      color: var(--color-alt-400);
    }
  }

  input {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
