.pageWrapper {
  padding: 1.25rem;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem 0;
}

.centeredTable {
  th {
    text-align: center !important;
  }
  td {
    text-align: center !important;

    button {
      margin: 0 auto;
    }
  }
}

.searchSection {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--color-gray-50);
  border-bottom: 1px solid var(--color-gray-100);

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
}

.pageContent {
  position: relative;
}

.sidebar {
  width: 20vw;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 91px - 46px);
  height: 100%;
  position: absolute;
  z-index: 100;
  top: 0;
}

.ModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal {
  width: 50%;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
}

.groupedButtons {
  display: flex;
  gap: 1rem;
}

.formSpacing {
  margin-bottom: 1rem;
}

.inlineGroup {
  display: flex;
  gap: 1rem;

  > div {
    width: 100%;
  }
}

.LoadingScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 2.5rem);
  gap: 1rem;

  h3 {
    margin: 0;
  }
}

.errorMessage {
  font-size: var(--font-size-s);
  color: var(--color-red-400);
}

.pagerWrapper {
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
