.loadingHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
  
  .loading {
    display: inline-block;
  }
  
  .loading:after {
    content: ' .';
    color: #000;
    animation: dots 2s steps(5, end) infinite;
  }

  .PageTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(1, 10, 44, 0.07);
    padding-bottom: 20px;
  
    .title {
      font-size: var(--font-size-xxl);
      font-weight: var(--font-light);
      color: #010a2c;
      margin-bottom: 0.625rem;
      margin-top: 2.5rem;
    }
    .subtitle,
    .counter {
      font-weight: 300;
      font-size: 36px;
      line-height: 50px;
    }
  
    .subtitle {
      color: rgba(1, 10, 44, 0.15);
    }
  
    .counter {
      color: rgba(1, 10, 44, 0.15);
      margin-left: 20px;
    }
  }

  .PageTabs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(1, 10, 44, 0.07);
    padding-bottom: 20px;
  
    .title {
      font-size: var(--font-size-xxl);
      font-weight: var(--font-light);
      color: #010a2c;
      margin-bottom: 0.625rem;
      margin-top: 2.5rem;
    }
    .subtitle,
    .counter {
      font-weight: 300;
      font-size: 20px;
      
    }
  
    .subtitle {
      color: rgba(1, 10, 44, 0.15);
    }
  
    .counter {
      color: rgba(1, 10, 44, 0.15);
      margin-left: 20px;
    }
  }

  .tableHeader {
    padding: 12px 16px;
    text-align: left;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
  }
  
  .tableCell {
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 16px;
  }
  
  .dropdownContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    gap: 15px;
  }
  
  .tableContainer {
    width: 100%;
    max-width: 800px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 15px;
  }
  
  .tabContainer {
    display: flex;
    margin-bottom: 20px;
    gap: 30px;
  }
  
  .tabButton {
    margin-right: 20px;
    background-color: transparent;
    color: black;
    border: none;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
    font-weight: 200;
    font-size: 20px;
  
    &.active {
      border-bottom: 2px solid black;
      font-weight: 400;
    }
  }
  
  .pageTitle {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .dropdownContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    gap: 15px;
  }
  
  .tableContainer {
    width: 100%;
    max-width: 800px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 15px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableRow {
    &:nth-child(even) {
      background-color: #f9f9f9;
    }
  }
  
  .tableHeader {
    padding: 12px 16px;
    text-align: left;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
  }
  
  .tableCell {
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 16px;
  }
  
  .tabContainer {
    display: flex;
    margin-bottom: 20px;
    gap: 30px;
  }
  
  .tabButton {
    margin-right: 20px;
    background-color: transparent;
    color: black;
    border: none;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
    font-weight: 200;
    font-size: 20px;
  
    &.active {
      border-bottom: 2px solid black;
      font-weight: 400;
    }
  }
  
  .pageTitle {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  
  .mainContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
    max-width: 100%;
    overflow: hidden;
  }
  
  .pageHeader {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }