.ListingCardShimmer {
	background: #ffffff;
	border: 1px solid rgba(1, 10, 44, 0.08);
	box-shadow: 0px 2px 0px rgba(1, 10, 44, 0.1);
	border-radius: 4px;

	.titleWrapper {
		display: flex;
		align-items: center;
		padding: 10px 24px 12px;

		.checkbox {
			flex: 0;
		}

		.title {
			flex: 1 1 auto;
			width: 80px;
			margin-left: 24px;
			padding-left: 20px;
			border-left: 1px solid rgba(#010a2c, 0.1);
			display: flex;
			flex-direction: column;

			> * + * {
				margin-top: 7px;
			}
		}
	}
}
