.main {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.backgroundImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hidden {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  animation: disappear 3s ease-in-out forwards;
}

@keyframes disappear {
  0% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 100% 0 0);
  }
}

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
  flex-direction: row;
}

.loaderContainer h3 {
  margin: 0;
  padding-left: 10px;
}

.preview_bg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.imageContainer img {
  position: relative;
}

.saveoption {
  position: absolute;
  top: 10px;
  right: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 10;

  button {
    border: none;
    border-radius: 17px;
    background: #ffd843;
    font-size: 16px;
    color: rgb(59, 57, 57);
    cursor: pointer;
    height: 32px;
    width: 65px;
    z-index: 20;

    &.active,
    &:hover {
      background: #ffc343;
    }
  }
}

.errorContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: red;
    font-weight: bold;
  }
}
