.Pager {
  display: flex;
  align-items: center;

  .buttons {
    display: flex;

    .button {
      cursor: pointer;
      background-color: #fbfbfb;
      border: none;
      margin-right: 1px;
      box-shadow: 0 0 0 1px rgb(221, 222, 226);
      height: 41px;
      line-height: 41px;
      min-width: 43px;
      text-align: center;

      &:first-child {
        border-radius: 3px 0 0 3px;
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
      }

      &.active {
        background: var(--color-prime-400);
        color: #fff;
      }
    }

    span {
      text-align: center;
      height: 41px;
      line-height: 41px;
      width: 40px;
      color: rgba(1, 10, 44, 0.2);
      background-color: rgba(241, 241, 243);
      box-shadow: 0 0 0 1px rgb(221, 222, 226);
    }
  }
  .dropdown {
    margin-left: 1rem;
  }
}
