.AdminSettingsSidebar {
  top: 1.25rem;
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: 2px;
  button {
    padding: 0.625rem 0.9375rem;
    background-color: var(--color-gray-50);
    border-radius: 6px;
    color: #010a2c;
    font-weight: var(--font-light);
    font-size: 1rem;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left;
    &:hover,
    &:focus,
    &.active {
      background-color: var(--color-prime-100);
      font-weight: var(--font-medium);
      color: var(--color-prime-400);
    }
  }
}
