.ListingCard {
  background: #ffffff;
  border: 1px solid rgba(1, 10, 44, 0.08);
  box-shadow: 0px 2px 0px rgba(1, 10, 44, 0.1);
  border-radius: 4px;

  .thumbWrapper {
    overflow: hidden;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 4px 0 0;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .playbox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      font-size: 50px;
      color: #fff;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(#2e53e3, 95%);
      padding: 10px 10px 20px 10px;
      opacity: 0;
      visibility: hidden;
      // transition: 0.2s ease 0s;
      display: flex;
      flex-direction: column;

      button {
        cursor: pointer;
      }

      .primeButton {
        color: #fff;
        font-size: 18px;
        text-align: left;
        padding: 20px 0;
        display: flex;
        border: none;
        border-bottom: 1px solid rgba(#ffffff, 8%);
        background-color: transparent;
        transition: 0.2s ease;
        align-items: center;
        text-decoration: none;

        svg {
          font-size: 24px;
          color: #fff;
          margin-right: 10px;
          margin-left: 10px;
        }

        &:hover {
          background-color: rgba(#fff, 5%);
        }
      }
      .overlayFooter {
        margin-top: auto;
        align-self: flex-end;
        padding: 0 10px 0 0;

        button {
          border: none;
          background-color: transparent;
          transition: 0.2s ease;
          font-size: 24px;
          color: #fff;

          &:hover {
            background-color: rgba(#fff, 5%);
          }
        }
      }
    }

    &:hover {
      .overlay {
        // transition: 0.2s ease 0.3s;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    padding: 10px 24px 12px;

    .checkbox {
      flex: 0;
    }

    .title {
      flex: 1 1 auto;
      width: 80px;
      margin-left: 24px;
      padding-left: 20px;
      border-left: 1px solid rgba(#010a2c, 0.1);
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
      }

      a,
      span {
        font-weight: 300;
        color: #010a2c;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 0;
      }

      small {
        font-size: 14px;
        color: #010a2c;
        opacity: 0.5;
        margin-top: 7px;
        min-width: 0;
      }
    }
  }
}

.processingAsset {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background: var(--color-prime-100);
  color: #0633de;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
}

.errorAsset {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background: rgba(255, 65, 84, 0.1);
  border-radius: 0.5rem;
  margin-right: 1rem;
  border: none;
}

.errorTitleStyle {
  color: #ff4154 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.titleHolder {
  display: inline-flex;
}
