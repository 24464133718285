.Progress_Status {
  width: 100%;
  margin-top: 20px;
  background-color: #ddd;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.myprogressBar {
  width: 1%;
  height: 15px;
  text-align: center;
  line-height: 32px;
  color: black;
  border-radius: 20px;
}

.Progress_Text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 500;
}
