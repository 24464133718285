.Button {
  --primary-color: var(--color-prime-400);
  --secondary-color: var(--color-alt-400);
  --tertiary-color: #333;
  --accept-color: var(--color-green-400);
  --decline-color: var(--color-red-400);
  --primary-color-alt: var(--color-prime-600);
  --primary-color-faded: var(--color-prime-100);
  --secondary-color-alt: var(--color-alt-600);
  --secondary-color-faded: var(--color-alt-100);
  --tertiary-color-alt: #fbfbfb;
  --tertiary-color-border: rgba(1, 10, 44, 0.08);
  --accept-color-alt: var(--color-green-600);
  --decline-color-alt: var(--color-red-600);
  --text-on-primary: #fff;
  --text-on-secondary: #fff;
  --text-on-tertiary: var(--color-gray-600);
  --text-on-accept: #fff;
  --text-on-decline: #fff;

  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: transparent;
  font-weight: var(--font-medium);
  font-size: var(--font-size-xxl);
  padding: 0.5rem 1.5rem;
  min-height: 2rem;
  min-width: 2rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;
  position: relative;

  .icon {
    font-size: 1.2em;
    display: inline-block;
  }

  span {
    padding: 0.5rem;
    transition: 0.2s ease;
  }

  &:focus {
    outline: blue solid 2px;
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }

  &.primary {
    background-color: var(--primary-color);
    color: var(--text-on-primary);

    &:hover {
      background-color: var(--primary-color-alt);
    }
    &:active {
      background-color: var(--primary-color);
      box-shadow: 0 0 0.5rem var(--primary-color);
    }

    &.outlined {
      background-color: transparent;
      color: var(--primary-color);
      box-shadow: inset 0 0 0 1px var(--primary-color);

      &:hover {
        color: var(--primary-color-alt);
        box-shadow: inset 0 0 0 1px var(--primary-color-alt);
      }
      &:active {
        background-color: var(--primary-color-faded);
        box-shadow: inset 0 0 0 1px var(--primary-color-alt), 0 0 0.5rem var(--primary-color);
      }
    }
  }

  &.secondary {
    background-color: var(--secondary-color);
    color: var(--text-on-secondary);

    &:hover {
      background-color: var(--secondary-color-alt);
    }
    &:active {
      background-color: var(--secondary-color);
      box-shadow: 0 0 0.5rem var(--secondary-color);
    }

    &.outlined {
      background-color: transparent;
      color: var(--secondary-color);
      box-shadow: inset 0 0 0 1px var(--secondary-color);

      &:hover {
        color: var(--secondary-color-alt);
        box-shadow: inset 0 0 0 1px var(--secondary-color-alt);
      }
      &:active {
        background-color: var(--secondary-color-faded);
        box-shadow: inset 0 0 0 1px var(--secondary-color-alt), 0 0 0.5rem var(--secondary-color);
      }
    }
  }

  &.tertiary {
    background-color: var(--tertiary-color-alt);
    color: var(--text-on-tertiary);
    border: 1px solid var(--tertiary-color-border);
    border-radius: 3px;
    padding: 0.4rem 0.5rem;

    &:hover {
      background-color: var(--tertiary-color-alt);
    }
    &:active {
      background-color: var(--tertiary-color);
      box-shadow: 0 0 0.5rem var(--tertiary-color-alt);
    }
  }

  &.small {
    min-width: 1.75rem;
    min-height: 1.75rem;

    span {
      font-size: var(--font-size-base);
      padding: 0.25rem;
    }
  }

  &.accept {
    background-color: var(--accept-color);
    color: var(--text-on-accept);

    &:hover {
      background-color: var(--accept-color-alt);
    }
    &:active {
      background-color: var(--accept-color);
      box-shadow: 0 0 0.5rem var(--accept-color-alt);
    }
  }

  &.decline {
    background-color: var(--decline-color);
    color: var(--text-on-decline);

    &:hover {
      background-color: var(--decline-color-alt);
    }
    &:active {
      background-color: var(--decline-color);
      box-shadow: 0 0 0.5rem var(--decline-color-alt);
    }
  }

  &.flat {
    background-color: transparent;
    padding: 0;
  }

  &[disabled] {
    filter: grayscale(1);
    opacity: 0.6;
    pointer-events: none;

    &.loading {
      filter: none;
      opacity: 0.95;
      cursor: default;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    display: flex;
    background-color: inherit;
    opacity: 0.9;
    border-radius: inherit;

    & > * {
      margin: auto;
    }

    & ~ span {
      filter: blur(3px);
    }
  }
}
