*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-base);
  min-height: 100vh;
  background-color: var(--bg);
}

#root {
  min-height: 100vh;
}

svg {
  display: block;
}

a {
  color: var(--color-alt-400);
  text-decoration: none;
  transition: 0.2s ease;

  &:hover {
    color: var(--color-prime-600);
  }

  &:focus {
    color: var(--color-prime-600);
    text-decoration: underline;
  }
}

.listingGridWrapper {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
}

h3 {
  font-weight: 300;
  font-size: 24px;
  line-height: 26px;
  color: #010a2c;
  margin-top: 0;
  margin-bottom: 20px;
}
