@mixin stacked-avatars($maxAvatars, $avatarSize, $spacing) {
  img,
  svg {
    &:first-child {
      transform: translateX(0);
    }

    @for $child from 1 through $maxAvatars {
      &:nth-child(#{$child + 1}) {
        transform: translateX(calc(-1 * $avatarSize * $spacing * $child));
      }
    }
  }

  margin-right: calc(-1 * $avatarSize * $spacing * ($maxAvatars - 1));
}

.CollectionCard {
  border-radius: 0.25rem;

  box-shadow: var(--shadow-1);

  .collectionImage {
    height: 11.25rem;
    width: 100%;
    position: relative;

    .groupCollection {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;

      background-color: var(--color-prime-100);
      border-radius: 9999px;
      border: 1px solid var(--color-prime-400);
      padding-inline: 0.5rem;
      padding-block: 0.25rem;
      font-size: var(--font-size-s);
      color: var(--color-prime-400);
      display: flex;
      gap: 0.25rem;
      justify-content: center;
      user-select: none;
    }

    .assetPreview {
      width: 100%;
      height: 100%;
      color: var(--color-gray-800);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      background-color: rgba(46, 83, 227, 0.9);
      opacity: 0;
      position: absolute;
      inset: 0;

      display: flex;
      flex-direction: column;

      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 1;
      }

      > button,
      > div {
        flex: 1 0 0;
      }

      > button {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-inline: 1.5rem;
      }

      .overlayFooter {
        display: flex;
        justify-content: flex-end;

        button {
          aspect-ratio: 1/1;
          display: flex;
          justify-content: center;
          align-items: center;

          @supports not (aspect-ratio) {
            padding-inline: 1rem;
          }
        }
      }

      button {
        cursor: pointer;
        background: transparent;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.08);
        color: white;

        transition: all 0.2s ease-in-out;

        &:disabled {
          cursor: auto;
          opacity: 0.5;
        }

        &:hover:not(:disabled) {
          background-color: rgba(0, 0, 0, 0.15);
        }
      }
    }
  }

  .collectionContent {
    padding: 1rem;

    .collectionTitleSection {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5,
      span {
        font-size: var(--font-size-xxl);
        font-weight: var(--font-light);
        line-height: var(--line-height-l);
      }

      h5 {
        max-width: 16ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
      }
      span {
        color: rgba(1, 10, 44, 0.2);
      }
    }

    .description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 0rem;
      margin-bottom: 0.875rem;

      font-size: var(--font-size-base);
      color: rgba(1, 10, 44, 0.2);
    }

    .collectionInfoSection {
      display: flex;
      gap: 1rem;

      span {
        color: rgba(1, 10, 44, 0.2);
        font-size: var(--font-size-s);
        font-weight: var(--font-normal);
        text-transform: uppercase;
      }
    }

    .collectionUsers {
      display: flex;
      align-items: center;

      img,
      svg {
        width: 2rem;
        height: 2rem;
        border: 2px solid white;
        border-radius: 9999px;
      }

      svg {
        color: var(--color-prime-400);
        background-color: var(--color-prime-100);
      }

      @include stacked-avatars(5, 2rem, 1/3);
    }
  }
}
