/** 
 * GLOBAL STYLES
 * Here import only the styles that are meant to be global 
 */

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

@import 'assets/styles/variables';
@import 'assets/styles/normalize';
@import 'assets/styles/base';

.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}

.alerts-wrapper {
  position: fixed;
  top: 0.5rem;
  right: 1rem;
  width: 380px;
  z-index: 9999;
  transform: translateX(0) translateZ(0);
  will-change: contents;

  > div {
    will-change: opacity, transform;
    margin-top: 0.5rem;
  }

  &.pushed {
    transform: translateX(-440px) translateZ(0);
  }
}

.fullscreen {
  margin: auto;
  height: 100%;
  display: flex;
}
