@mixin stacked-avatars($maxAvatars, $avatarSize, $spacing) {
  img,
  svg {
    &:first-child {
      transform: translateX(0);
    }

    @for $child from 1 through $maxAvatars {
      &:nth-child(#{$child + 1}) {
        transform: translateX(calc(-1 * $avatarSize * $spacing * $child));
      }
    }
  }

  margin-right: calc(-1 * $avatarSize * $spacing * ($maxAvatars - 1));
}

.CollectionRow {
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 0px rgb(1 10 44 / 10%);
  border: 1px solid rgba(1, 10, 44, 0.08);
  max-width: 100%;

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;

    &:first-child {
      overflow: hidden;
    }

    img,
    svg {
      flex: 1 0 auto;
    }
  }

  .cover {
    height: 6.25rem;
    width: 6.25rem;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    color: var(--color-gray-800);
  }

  .content {
    padding-block: 0.5rem;

    overflow: hidden;
    max-width: 26ch;
    text-overflow: ellipsis;

    @media screen and (min-width: 1280px) {
      max-width: 36ch;
    }

    @media screen and (min-width: 1440px) {
      max-width: 40ch;
    }

    .title {
      font-weight: var(--font-normal);
      color: #010a2c;
      white-space: nowrap;
    }

    .description {
      white-space: nowrap;
    }

    .info {
      margin-top: 0.5rem;
      display: flex;
    }
  }

  p {
    margin: 0;
    color: #010a2c;
    opacity: 0.5;
    font-size: var(--font-size-s);
  }

  .users {
    display: flex;
    align-items: center;

    img,
    svg {
      width: 2rem;
      height: 2rem;
      border: 2px solid white;
      border-radius: 9999px;
    }

    svg {
      color: var(--color-prime-400);
      background-color: var(--color-prime-100);
    }

    @include stacked-avatars(5, 2rem, 1/3);
  }

  .groupCollection {
    background-color: var(--color-prime-100);
    border-radius: 9999px;
    border: 1px solid var(--color-prime-400);
    padding-inline: 0.5rem;
    padding-block: 0.25rem;
    font-size: var(--font-size-s);
    color: var(--color-prime-400);
    display: flex;
    gap: 0.25rem;
    width: 5rem;
    justify-content: center;
  }

  .lastUpdate {
    width: 8rem;
    text-align: center;
  }

  .actions {
    display: flex;
    padding-right: 2rem;

    button {
      color: var(--color-prime-400);
    }
  }
}
