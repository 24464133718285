.BaseModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(1, 1, 1, 0.25);
  z-index: 1337;
  overflow-y: auto;
}

.BaseModal {
  width: 50%;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  position: relative;
}

.BaseModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CloseModal {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
