.pageWrapper {
  margin-top: 0;
}
.container {
  padding: 1.25rem;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.25rem;

  .addonsListContainer {
    grid-column: span 3 / span 3;

    border-radius: 6px;

    padding: 0.625rem 0.9375rem;

    @media screen and (max-width: 1024px) {
      grid-column: span 4 / span 4;
    }

    h3 {
      font-size: var(--font-size-xxl);
      font-weight: var(--font-light);
      color: #010a2c;
      margin-bottom: 0.625rem;
      margin-top: 2.5rem;

      margin: 0;
      margin-bottom: 0.625rem;

      span {
        opacity: 0.3;
      }
    }

    .addonsList {
      background-color: var(--color-gray-50);

      div {
        display: flex;
        gap: 0.625rem;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: var(--font-size-s);
          flex: 1 0 0;
        }

        div,
        button {
          flex: 0 0 auto;
        }

        margin-bottom: 0.625rem;
      }
    }
  }

  .content {
    grid-column: span 5 / span 5;
    // padding-right: 8.75rem;

    @media screen and (max-width: 1024px) {
      padding-right: 4rem;
      grid-column: span 8 / span 8;
    }
  }

  .content_settings {
    grid-column: span 4 / span 4;

    @media screen and (max-width: 1024px) {
      padding-right: 4rem;
      grid-column: span 8 / span 8;
    }
  }
}

.sectionDescription {
  font-size: var(--font-size-s);
  font-weight: var(--font-normal);
  color: #010a2c;
  opacity: 0.5;
  margin-bottom: 0;
}

.webhookOptions {
  h2 {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-light);
    color: #010a2c;
    margin-bottom: 0.625rem;
    margin-top: 2.5rem;
  }

  .webhookOption {
    background-color: var(--color-gray-50);
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2px;
    // justify-content: space-between;(to remove the align center of the addon names.)
  }
}

.inlineButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 46px;
  height: 46px;
  background-color: red;
}

.clearUpperMargin {
  h2 {
    margin-top: 0 !important;
  }
}

.title {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-light);
  color: #010a2c;
  margin-bottom: 0.625rem;
  margin-top: 0px;
  width: 100%;
  user-select: none;
}

.editor {
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
  // min-height: 60%;
}

.gearHolder {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: all 0.3s;
}

.btnHolder {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.AddonSettingBtn {
  min-width: 150px !important;
  border-radius: 10px !important;
}

.formatBtn {
  width: 100%;
  background-color: #4c4c46;
  color: #fff;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-block: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.formatBtn:hover {
  background-color: #3b3b3b; /* Darken the background color on hover */
}

.footerNotes {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 13px;
  color: grey;
}
