.inputWrapper {
  --color-error-light: var(--color-red-100);
  --color-error-dark: var(--color-red-400);
  --color-gray-dark: var(--color-gray-200);

  position: relative;

  p {
    font-size: var(--font-size-base);
  }

  .inputIcon {
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    color: var(--color-prime-400);
    transition: 0.3s;
    font-size: 26px;
    pointer-events: none;

    &.inputIconError {
      color: var(--color-error-dark);
    }
  }

  .Input {
    border: 1px solid var(--color-gray-dark);
    border-radius: 4.5rem;
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
    font-size: var(--font-size-l);
    transition: 0.3s;
    width: 100%;
    position: relative;
    // z-index: 3;
    color: var(--color-gray-600);

    &.inputWithIcon {
      padding-left: 2rem;
    }

    &.secondary {
      border-color: rgba(1, 10, 44, 0.1);
      border-radius: 0.375rem;
      padding: 0.5625rem 1rem;
    }

    &.error {
      border-color: var(--color-error-dark);
      background-color: var(--color-error-light);
      box-shadow: 0px 0px 4px var(--color-error-dark);

      &::placeholder {
        color: var(--color-error-dark);
      }
    }

    &:disabled {
      border-color: #f6f6f3;
      font-style: italic;
      background-color: #fff;
      color: var(--color-gray-dark);
      font-weight: var(--font-light);

      & + .inputIcon {
        color: var(--color-gray-dark);
      }

      &::placeholder {
        color: var(--color-gray-dark);
        font-weight: var(--font-light);
      }
    }

    &::placeholder {
      font-style: italic;
    }

    &:focus-visible {
      outline: none;
      box-shadow: var(--shadow-1);
    }

    &:focus {
      & + .inputIcon {
        color: #000;
      }
    }
  }

  .actionWrapper {
    display: flex;

    .editContainer {
      position: relative;
      top: -0.25rem;
      z-index: 1;
      padding: 0.5rem 0.5rem 0.25rem 0.5rem;
      background-color: var(--color-gray-dark);
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      bottom: 0;
      display: flex;
      align-items: center;

      &.active {
        opacity: 1;
      }

      .editButton {
        min-width: 1rem;
        min-height: 1rem;

        > span {
          padding: 0;
        }

        .editIcon {
          display: inline;
          font-size: var(--font-size-s);
          cursor: pointer;

          &.checkIcon {
            color: var(--color-green-400);
            margin-right: 0.25rem;
          }

          &.xIcon {
            color: var(--color-error-dark);
            margin-left: 0.25rem;
          }
        }
      }
    }

    .limitContainer {
      margin-left: auto;

      .limitWrapper {
        display: flex;
        align-items: center;
        color: var(--color-gray-400);
        font-size: var(--font-size-xxs);
        margin-top: 0.5rem;

        svg {
          font-size: var(--font-size-s);
          margin-right: 0.25rem;
        }

        &.warning {
          .limitText {
            color: var(--color-prime-400);
          }

          svg {
            color: var(--color-prime-400);
            cursor: pointer;
          }
        }
      }
    }
  }

  .errorContainer {
    margin-top: 0.25rem;
    align-items: center;
    display: flex;

    .errorIcon {
      margin-right: 0.5rem;
      top: 0.1rem;
      color: var(--color-error-dark);
    }

    .errorText {
      color: var(--color-error-dark);

      font-size: var(--font-size-xs);
    }
  }
}

