.Tooltip {
  position: relative;
  display: inline-block;

  &.blocky {
    display: block;
  }

  .bubble {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease;
    font-size: var(--font-size-xs);
    border-radius: 0.25rem;
    background-color: var(--color-alt-700);
    color: var(--color-gray-100);
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
    pointer-events: none;
    z-index: 99;
    user-select: none;
    line-height: 1rem;
  }

  &:hover {
    .bubble {
      visibility: visible;
      opacity: 0.95;
    }
  }

  &.moveText:hover .bubble {
    transform: translate(-3px, 0); 
   
  }

  &.delayed {
    &:hover {
      .bubble {
        transition: 0.2s ease 1.5s;
      }
    }
  }

  &.top {
    .bubble {
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, -4px);
    }
  }
  &.left {
    .bubble {
      right: 100%;
      top: 50%;
      transform: translate(-4px, -50%);
    }
  }
  &.right {
    .bubble {
      left: 100%;
      top: 50%;
      transform: translate(4px, -50%);
    }
  }
  &.bottom {
    .bubble {
      top: 100%;
      left: 50%;
      transform: translate(-50%, 4px);
    }
  }
}
