.PrivateField {
  .hiddenField {
    filter: blur(5px);
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }

  .revealButton {
    margin-top: 1rem;
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
    font-size: var(--font-size-s);

    &:hover {
      color: var(--color-prime-400);
    }
  }
}
