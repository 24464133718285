.AdminNavBar {
  display: flex;
  background-color: var(--color-prime-400);
  border-top: 4px solid #11236a;

  justify-content: space-between;

  .navRoutes {
    display: flex;

    align-items: center;

    .logoIcon {
      color: white;
      width: 2.5rem;
      height: auto;
      padding: 0.5rem;
    }
  }

  .navActions {
    display: flex;

    .notificationsDropdown {
      > div {
        width: 20rem;
        margin-top: 0;
        padding-top: 1rem;

        span {
          justify-content: center;
        }
      }
    }
  }

  a {
    padding: 0.625rem 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    font-size: var(--font-size-l);
    font-weight: var(--font-normal);
    text-decoration: none;

    svg {
      color: #010a2c;
    }

    &.active,
    &:hover {
      /*background-color: #2542b6cc !important;*/
      font-weight: var(--font-medium);
      color: white;
      text-decoration: underline white;

      svg {
        color: var(--color-gray-50);
      }
    }
  }
}

.dropdown {
  button {
    background: transparent;
    border: none;

    svg {
      margin-left: 0;
    }
  }

  > div {
    min-width: 230px;
    padding-bottom: 0px;
  }
}

.dropdown_template {
  font-size: 1.15rem;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown_icon {
  margin-left: 7px !important;
  color: #ffffff !important;
  font-size: 15px;
}
