.loadingHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.loading {
  display: inline-block;
}

.loading:after {
  content: ' .';
  color: #000;
  animation: dots 2s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #000;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
  }
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px;
  padding-block: 20px;
  padding-bottom: 10px;
  background-color: #f9f9f9;
}

.headingInner {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.headingTitle {
  margin: 0;
  font-size: 28px;
}

.divider {
  height: 30px;
  width: 1px;
  border: 1px solid grey;
}

.headingId {
  color: grey;
  font-size: 18px;
  margin: 0;
}

.statusContainer {
  width: 200px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
}

.subHeadingContainer {
  display: inline-flex;
  background-color: #f9f9f9;
  padding: 10px;
  width: 100%;
  gap: 10px;
}

.subHeadingItem {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  color: grey;
  font-size: 14px;
}

.subHeadingText {
  color: #000;
}

.processTabContainer {
  font-weight: 300;
  padding: 10px;
  font-size: 18px;
}

.processTabName {
  margin-bottom: 5px;
}

.processTabWrapper {
  width: inherit;
  background-color: #f9f9f9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.processStatus {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  padding: 5px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.processDetails {
  padding: 20px;
  width: 100%;
}

.processDetailItem {
  color: gray;
  display: block;
  font-size: 13px;
  margin-block: 5px;
}

.processDetailText {
  color: #000;
  font-weight: 400;
}

.stepsTabContainer {
  width: 100%;
  padding-inline: 10px;
}

.stepsTabWrapper {
  width: inherit;
  background-color: #f9f9f9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-inline: 20px;
}

.stepsTabName {
  display: flex;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 60px;
  border-radius: 10px;
  font-size: 17px;
  background-color: #9e9e9e;
  font-weight: 500;
  color: #fff;
}

.stepsStatus {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  padding: 5px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.stepsDetails {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 4;
}

.inputViewContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.inputViewHeader {
  font-weight: 300;
  padding: 10px;
  font-size: 18px;
}

.inputViewContent {
  display: flex;
  flex-direction: column;
}

.inputViewItem {
  color: gray;
  display: block;
  padding-left: 30px;
  font-size: 13px;
  margin-block: 10px;
  font-weight: 300;
}

.inputViewText {
  color: #000;
  font-weight: 400;
}

.statusLayoutContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.statusLayoutContent {
  display: flex;
  flex: 1;
  padding-inline: 10px;
}

.statusLayoutLeft {
  display: flex;
  flex: 1;
}

.statusLayoutRight {
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
}

.leftColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.processTabsContainer {
  display: flex;
  flex: 3;
  flex-direction: column;
}

.stepsTabContainer {
  font-weight: 300;
  padding: 10px;
  font-size: 18px;
}

.stepsHeader {
  font-weight: 300;
  padding: 10px;
  font-size: 18px;
}

$base-spacing-unit: 16px;
$half-spacing-unit: $base-spacing-unit / 2;

$color-alpha: #1772ff;
$color-form-highlight: #eeeeee;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-top: 0px;
  border-radius: 10px;
  overflow: hidden;
}

.table {
  width: 100%;
  border: 1px solid $color-form-highlight;
}

.table_header {
  display: flex;
  width: 100%;
  background: #000;
  padding: ($half-spacing-unit * 1.5) 0;
}

.table_row {
  display: flex;
  width: 100%;
  padding: ($half-spacing-unit * 1.5) 0;

  &:nth-of-type(odd) {
    background: $color-form-highlight;
  }
}

.table_data,
.header__item {
  flex: 1 1 20%;
  //   text-align: center;
  font-size: 12px;
}

.header__item {
  text-transform: uppercase;
}

.filter__link {
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-left: $base-spacing-unit;
  padding-right: $base-spacing-unit;

  &::after {
    content: '';
    position: absolute;
    right: -($half-spacing-unit * 1.5);
    color: white;
    font-size: $half-spacing-unit;
    top: 50%;
    transform: translateY(-50%);
  }

  &.desc::after {
    content: '(desc)';
  }

  &.asc::after {
    content: '(asc)';
  }
}
