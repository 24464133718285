.checkbox_sty {
  font-size: 13px;
}

.textarea_sty {
  flex: 1;
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 20px;
  outline: none;
  height: 100%;
  resize: none;
  width: 100%;
}

.error_sty {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  padding: 10px;
  background-color: #dc3545;
  margin: 10px;
  width: 100%;
  text-align: center;
  border-radius: 10px;
}

.checkbox_title {
  font-size: 14px;
  margin-block: 10px;
}

.modalRightHolder {
  flex: 1;
  overflow-y: scroll;
}

.modalLeftHolder {
  display: flex;
  position: relative;
  flex: 3;
  flex-direction: column;
  align-items: center;
}

.btnHolder {
  margin-top: 10px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
