.pageWrapper {
  margin-top: 0;
}
.container {
  padding: 1.25rem;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.25rem;

  .sidebar {
    grid-column: span 3 / span 3;

    nav {
      top: 1.25rem;
      position: sticky;
      display: flex;
      flex-direction: column;
      gap: 2px;
      button {
        padding: 0.625rem 0.9375rem;
        background-color: var(--color-gray-50);
        border-radius: 6px;
        color: #010a2c;
        font-weight: var(--font-light);
        font-size: 1rem;
        text-decoration: none;
        border: none;
        outline: none;
        cursor: pointer;
        text-align: left;
        &:hover,
        &:focus,
        &.active {
          background-color: var(--color-prime-100);
          font-weight: var(--font-medium);
          color: var(--color-prime-400);
        }
      }
    }
  }

  .content {
    grid-column: span 9 / span 9;
    padding-right: 8.75rem;

    @media screen and (max-width: 1024px) {
      padding-right: 4rem;
    }
  }
}

.sectionDescription {
  font-size: var(--font-size-s);
  font-weight: var(--font-normal);
  color: #010a2c;
  opacity: 0.5;
  margin-bottom: 0;
}

.webhookOptions {
  h2 {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-light);
    color: #010a2c;
    margin-bottom: 0.625rem;
    margin-top: 2.5rem;
  }

  .webhookOption {
    background-color: var(--color-gray-50);
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2px;
  }
}

.inlineButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
