.PageTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba(1, 10, 44, 0.07);
	padding-bottom: 20px;

	.title,
	.subtitle,
	.counter {
		font-weight: 300;
		font-size: 36px;
		line-height: 50px;
	}

	.subtitle {
		color: rgba(1, 10, 44, 0.15);
	}

	.counter {
		color: rgba(1, 10, 44, 0.15);
		margin-left: 20px;
	}
}
