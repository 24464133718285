.ListingRow {
  background: #ffffff;
  border: 1px solid rgba(1, 10, 44, 0.08);
  box-shadow: 0px 2px 0px rgba(1, 10, 44, 0.1);
  border-radius: 4px;
  min-height: 10px;
  border-radius: 3px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  min-width: auto;
  @media screen and (min-width: 1024px) {
    width: 204%;
  }
  justify-content: space-between;
  .thumbWrapper {
    overflow: hidden;
    width: 100px;
    height: 100px;
    border-radius: 3px 0 0 3px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .playbox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      font-size: 24px;
      color: #fff;
    }
  }
  .substitutionWord {
    margin-left:'20px'
  }
  .actions {
    padding-right: 1.25rem;
    button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 10px;
      font-size: 24px;
      color: var(--color-prime-400);
      transition: 0.2s ease;
      margin-left: 20px;

      &:hover {
        color: var(--color-prime-600);
      }
    }
  }

  .checkbox {
    flex: 0;
    padding: 20px 34px;
  }

  .title {
    flex: 1 1 auto;
    width: 80px;
    margin-right: 24px;
    margin-left: 24px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;

    .fileNameContainer {
      display: flex;
      align-items: center;
    }

    p {
      margin: 0;
    }

    a {
      font-weight: 300;
      color: #010a2c;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
    }

    small {
      font-size: 14px;
      color: #010a2c;
      opacity: 0.5;
      margin-top: 7px;
      min-width: 0;
    }
  }
}

.processingAsset {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background: var(--color-prime-100);
  color: #0633de;
  font-size: var(--font-size-s);
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
}

.Dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
  }
  .h3 {
    color: #111; font-size: 16px
  }
  .dialogButton {
    display: flex; margin-right: 15px;
  }
}

