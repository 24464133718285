.Badge {
  background-color: var(--color-prime-100);
  border-radius: 9999px;
  border: 1px solid var(--color-prime-400);
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: var(--font-size-s);
  color: var(--color-prime-400);
  display: flex;
  gap: 0.25rem;
  width: 5rem;
  justify-content: center;
}
