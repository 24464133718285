.faceSearchFloatingWindow {
    position: absolute;
    top: 50px; /* Adjust as needed to position below the icon */
    right: 3rem;
    z-index: 3;
    background-color: white;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:2rem;
    padding-left: 20px;
    
  }

  .faceSearchDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .faceDeviceView {
    width: 300px;
    height: 200px;
    border-radius: 20px;
    background-color: #696969;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .line {
    border: none;
    height: 1px; /* Adjust the thickness of the line */
    background-color: #ccc; /* Adjust the color */
    width: 85%; /* Adjust the width */
    margin: 15px 0; /* Adjust spacing around the line */
  }
  
  .clickPicBtn {
    width: 50px;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    background-color: #002feb;
    right: 10px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .browseBtn {
    font-size: 14px;
    margin-bottom: 10px; /* Adjust spacing around the line */
    padding: 3px;
    padding-inline: 7px;
    border-radius: 20px;
    margin-left: -5px;
  }

  .noCameraAccessTxt{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #ffffff;
    flex-direction: column;
  }

  .imageHolder{
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: scaleX(-1);
  }

  .fileInput{
    display: none;
  }

  .requestPermissionBtn{
    font-size: 14px;
  }

  .filters {
    margin-left: 0.2rem;
    max-width: 400px; 
    overflow-x: hidden; 
    padding: 1rem; 
    border-left: 1px solid #ddd;
    // border-radius: 8px;
    overflow-y: hidden;

   
  }


  