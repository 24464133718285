.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.125rem;
  background-color: var(--bg);

  .notificationsDropdown {
    > div {
      width: 20rem;
      margin-top: 0;
      padding-top: 1rem;

      span {
        justify-content: center;
      }
    }
  }

  .dropdown {
    margin-left: 1rem;

    > button {
      font-size: 2rem;
      color: var(--color-gray-600);
      padding: 0;
      min-height: auto;
      border: none;

      > svg {
        margin-left: 0;
      }
    }
  }

  nav {
    a {
      color: var(--color-gray-400);
      font-size: var(--font-size-xxl);
      line-height: 1;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      margin: 0 1rem;

      &:hover,
      &.active {
        background-color: var(--color-prime-100);
        color: var(--color-prime-400);
      }
    }
  }

  .icons {
    display: flex;

    a {
      margin-left: 1rem;
      font-size: 2rem;
      color: var(--color-gray-600);
    }
  }
}
