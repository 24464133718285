.SettingsNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg);
  padding: 1.25rem 1.25rem;

  nav {
    margin-left: -1.25rem;
    a {
      color: var(--color-gray-400);
      font-size: var(--font-size-xxl);
      line-height: 1;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      margin: 0 0.625rem;

      &:hover,
      &.active {
        background-color: var(--color-prime-100);
        color: var(--color-prime-400);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;

    button {
      align-self: end;
    }
  }
}
