.BatchActions {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  flex: 1 0 auto;

  > div {
    display: flex;
    gap: 1rem;
  }

  .selectAll {
    span:last-child {
      font-size: var(--font-size-s);
    }
  }

  .batchAction {
    display: flex;

    font-size: var(--font-size-s);

    .batchActionPrimaryButton {
      &:not([data-no-dropdown='true']) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none;
      }

      span {
        font-size: var(--font-size-s);
      }
    }

    .batchActionDropdown {
      button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-color: rgba(1, 10, 44, 0.08);
        gap: 0.5rem;

        span {
          font-size: var(--font-size-s);
          font-weight: var(--font-medium);
        }

        svg {
          margin-left: 0;
        }
      }

      div {
        //padding-inline: 0;

        width: 15rem;

        > span {
          font-size: var(--font-size-s);
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          margin-bottom: 0;
          padding-inline: 0;
          border-bottom-color: rgba(1, 10, 44, 0.08);
        }

        > span:first-child {
          margin-bottom: 0;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          font-size: var(--font-size-xs);
          padding-inline: 0.75rem;
          color: var(--color-prime-400);
          font-weight: var(--font-medium);

          margin-inline: -16px;
          border-bottom: 1px solid rgba(1, 10, 44, 0.08);
        }
      }
    }

    > *:only-child {
      border-radius: 0.25rem;
      button {
        border-radius: 0.25rem;
      }
    }
  }

  .basketButton {
    display: flex;
    align-items: center;

    > span {
      padding: 0 !important;
    }

    div {
      margin-left: 0.5rem;
      display: flex;
      > span {
        &:first-of-type {
          color: var(--color-prime-400);
          background-color: var(--color-prime-100);
          border-radius: 0.33rem;
          width: 1.25rem;
        }

        + span {
          font-size: var(--font-size-s);
        }
      }
    }
  }
}

.badge {
  color: var(--color-prime-400);
  background-color: var(--color-prime-100);
  border-radius: 0.33rem;
  padding: 0.25rem;
}
