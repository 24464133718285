.FileUpload {
  min-height: 10.625rem;
  border: 2px dashed rgba(1, 10, 44, 0.15);
  border-radius: 0.5rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.75rem;

  &.dragging {
    border-color: var(--color-prime-400);
  }

  h4 {
    font-weight: var(--font-normal);
    font-size: var(--font-size-l);
    line-height: var(--line-height-s);
    color: rgba(1, 10, 44, 0.4);
    margin: 1rem 0 0.625rem 0;

    span {
      color: var(--color-prime-400);
    }
  }

  h5 {
    text-transform: uppercase;
    color: #010a2c;
    opacity: 0.2;
    font-size: var(--font-size-s);
    font-weight: var(--font-normal);
    margin: 0;
  }
}
