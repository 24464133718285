.pageWrapper {
  margin-top: 0;
}
.container {
  padding: 1.25rem;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.25rem;

  .sidebar {
    grid-column: span 3 / span 3;

    nav {
      top: 1.25rem;
      position: sticky;
      display: flex;
      flex-direction: column;
      gap: 2px;
      a {
        padding: 0.625rem 0.9375rem;
        background-color: var(--color-gray-50);
        border-radius: 6px;
        color: #010a2c;
        font-weight: var(--font-light);
        font-size: 1rem;
        text-decoration: none;
        &:hover,
        &:focus,
        &.active {
          background-color: var(--color-prime-100);
          font-weight: var(--font-medium);
          color: var(--color-prime-400);
        }
      }
    }
  }

  .content {
    grid-column: span 9 / span 9;
    padding-right: 8.75rem;

    @media screen and (max-width: 1024px) {
      padding-right: 4rem;
    }
  }
}

.section {
  .sectionTitle {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-light);
    color: #010a2c;
    margin-bottom: 0.625rem;
    margin-top: 2.5rem;
  }

  .sectionSubTitle {
    margin-left: 1.25rem;
    font-size: 1rem;
    font-weight: 700;
  }

  .sectionBody {
    background-color: var(--color-gray-50);
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1rem 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sectionDescription {
    font-size: var(--font-size-s);
    font-weight: var(--font-normal);
    color: #010a2c;
    opacity: 0.5;
    margin-bottom: 0;
  }
}

.inlineButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.onDemandImages {
  display: flex;
  align-items: center;
  gap: 1rem;
}

table {
  border-spacing: 0 2px;
  width: 100%;

  thead {
    th {
      color: #010a2c;
      opacity: 0.5;
      font-family: Ubuntu;
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size-s);
      line-height: var(--line-height-xs);
      font-weight: var(--font-normal);
      text-transform: uppercase;
      text-align: left;
      letter-spacing: 1px;

      padding: 0.5rem 1.625rem;
    }
  }

  tbody {
    tr {
      background-color: var(--color-gray-50);
      td {
        padding: 1.0625rem 1.625rem;
        text-align: center;

        .centeredCheckbox {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.watermarkFile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dragAndDrop {
    height: 10.625rem;
    border: 2px dashed rgba(1, 10, 44, 0.15);
    border-radius: 0.5rem;
    width: 55%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.dragging {
      border-color: var(--color-prime-400);
    }

    h4 {
      font-weight: var(--font-normal);
      font-size: var(--font-size-l);
      line-height: var(--line-height-s);
      color: rgba(1, 10, 44, 0.4);
      margin: 1rem 0 0.625rem 0;

      span {
        color: var(--color-prime-400);
      }
    }
    h5 {
      text-transform: uppercase;
      color: #010a2c;
      opacity: 0.2;
      font-size: var(--font-size-s);
      font-weight: var(--font-normal);
      margin: 0;
    }
  }

  .previewWatermark {
    width: 45%;
    margin: 0 3.75rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    > div {
      border: 1px solid rgba(1, 10, 44, 0.1);
      border-radius: 0.375rem;
      padding: 1.75rem 2.125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 2.5rem;
        width: auto;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    gap: 1rem;
    .previewWatermark,
    .dragAndDrop {
      width: 100%;
    }
  }
}

.watermarkOptions {
  h5 {
    color: #010a2c;
    opacity: 0.5;
    font-size: var(--font-size-s);
    font-weight: var(--font-normal);
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 0.625rem;
  }

  p {
    font-size: var(--font-size-s);
    font-weight: var(--font-normal);
    color: #010a2c;
    opacity: 0.5;
    margin: 0;
    width: 40ch;
    margin-top: 0.625rem;
  }

  .watermarkOptionsRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    div {
      width: 50%;
    }
  }

  .watermarkOptionsPreview {
    width: 100%;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .watermarkPositionPreview {
      position: absolute;
      width: auto;
      height: 60px;
    }
  }
}

.error {
  color: var(--color-red-400);
  font-size: var(--font-size-s);
  font-weight: var(--font-normal);
}
