.onboarding {
  header {
    display: flex;
    justify-content: center;
    padding-block: 2.5rem;
    border-bottom: 1px solid rgba(1, 10, 44, 0.12);
  }

  .container {
    margin-top: 2.5rem;
    max-width: 65vw;
    margin-inline: auto;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-block: 2.5rem;
      padding-inline: 10rem;

      h1 {
        margin: 0;
        font-size: 1.5rem;
        line-height: 2.25rem;
        font-weight: var(--font-light);
        margin-bottom: 4px;

        span {
          color: var(--color-prime-400);
        }
      }

      p {
        font-size: 1rem;
        font-weight: var(--font-normal);
        line-height: 1.625rem;
        text-align: center;
        margin: 0;
        margin-bottom: 1.25rem;
      }
    }
  }
}

.stepBar {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  padding: 1.25rem;

  border-bottom: 1px solid rgba(1, 10, 44, 0.12);

  > div:not(.stepInfo) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  > div {
    width: 1 / 3 * 100%;
  }

  .stepInfo {
    text-align: center;
    span {
      color: #010a2c;
      opacity: 0.3;
      font-size: 0.875rem;
      text-transform: uppercase;
    }

    h3 {
      font-size: 1.5rem;
      line-height: 2.25rem;
      margin: 0;
    }
  }
}

.step {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .container {
    .stepInfo {
      font-size: 1rem;
      line-height: 1.625rem;
      text-align: center;
    }

    flex: 1 0 auto;
    margin-top: 2.5rem;
    max-width: 65vw;
    margin-inline: auto;
  }
}

.stepFooter {
  padding-block: 2.5rem;
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  p {
    font-size: 1.125rem;
    font-weight: var(--font-light);
    line-height: 1.375rem;
    color: #010a2c;
    opacity: 0.5;
    text-transform: lowercase;
  }
}
