.ColorPicker {
  position: relative;

  .colorInput {
    display: flex;
    gap: 0.625rem;
    padding: 0.5rem;
    background-color: white;
    border: 1px solid rgba(1, 10, 44, 0.08);
    border-radius: 6px;

    .colorPreview {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 2px;
    }

    input {
      border: none;
      background: none;
      outline: none;
      width: 8ch;
      padding: 0;
      margin: 0;
    }
  }
}

.picker {
  position: absolute;
  z-index: 1337;

  .cover {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
