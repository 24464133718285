.textarea_sty {
  flex: 1;
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 20px;
  outline: none;
  height: 100%;
  resize: none;
  width: 100%;
}

.error_sty {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  background-color: #dc3545;
  text-align: center;
  border-radius: 10px;
  display: block;
  margin: 10px;
  min-height: 35px;
}

.btnHolder {
  margin: 10px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.expiry_input {
  width: 100px !important;
  border-radius: 5px !important;
}

.Select {
  padding: 0.85rem !important;
  margin-top: -15px !important;
}

.content_input {
  border-radius: 5px !important;
}

.modal_header {
  font-weight: 500;
  margin-inline: 10px;
  color: var(--color-prime-400);
  margin-top: 20px;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
}

.option_header {
  margin-block: 5px;
  margin-left: 5px;
}

.option_holder {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.urlCell {
  padding: 7px;
  border-radius: 5px;
  margin-block: 5px;
  font-size: 13px;
  word-wrap: break-word;
}

div.table-title {
  display: block;
  margin: auto;
  max-width: 600px;
  padding: 5px;
  width: 100%;
}

.table-title h3 {
  color: #fafafa;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: 'Roboto', helvetica, arial, sans-serif;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}

/*** Table Styles **/

.table-fill {
  background: white;
  border-radius: 3px;
  border-collapse: collapse;
  height: 320px;
  margin: auto;
  max-width: 600px;
  padding: 5px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation: float 5s infinite;
}

.table_head {
  background-color: #ebebeb;
  opacity: 1;
  border-right: 1px solid whitesmoke;
  color: #666b85;
  font-weight: 500;
  padding: 14px;
  text-align: left;
  vertical-align: middle;
}

.table_head:first-child {
  border-top-left-radius: 10px;
}

.table_head:last-child {
  border-top-right-radius: 10px;
  border-right: none;
}

.table_row {
  border-top: 1px solid #c1c3d1;
  border-right: 1px solid whitesmoke;
  color: #666b85;
  font-size: 16px;
  font-weight: normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}

.table_row:first-child {
  border-top: none;
}

.table_row:last-child {
  border-bottom: none;
}

.table_row:nth-child(even) td {
  background: #ebebeb;
}

.table_row:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.table_row:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.table_data {
  background: #ffffff;
  padding: 10px;
  text-align: left;
  vertical-align: middle;
  font-size: 13px;
  border-right: 1px solid whitesmoke;
  word-wrap: break-word;
}

.table_data:last-child {
  border-right: 0px;
}

.text_center {
  text-align: center;
}
