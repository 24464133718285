.pageWrapper {
  display: flex;
  flex: 1 0 auto;
}

.filtersContent {
  flex: 0 0 330px;
  width: 330px;
  padding: 20px 20px 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  .rangeValues {
    // display: flex;
    // align-items: center;

    > div {
      // width: 50%;
    }

    input {
      // padding-right: 2px;
      // padding-left: 2px;
    }
  }
}

.foldableContainer {
  margin-bottom: 30px;

  h4 {
    font-size: 21px;
    display: flex;
    user-select: none;
    cursor: pointer;
    font-weight: 500;

    svg {
      font-size: 24px;
      margin-right: 10px;
      color: #2e53e3;
    }
  }

  .filterChildren {
    padding-left: 34px;

    > * {
      margin-bottom: 14px;
    }
  }
}

.pageContent {
  flex: 1 1 auto;
  padding: 20px;

  display: flex;
  flex-direction: column;

  .shimmer {
    margin-bottom: 10px;
  }
}

.filtersWrapper {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;

  .tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
      margin-right: 10px;
    }
  }

  .actions {
    display: flex;

    > * {
      margin-left: 10px;
    }
  }
}

.pagerWrapper {
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
}

.searchBar {
  margin-inline: -1.25rem;
}

.pageActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 1rem;
}

.Select {
  margin-top: 0px;
}
