.pageWrapper {
  display: flex;
  flex: 1 0 auto;
}

.pageContent {
  flex: 1 1 auto;
  padding: 20px;

  display: flex;
  flex-direction: column;

  .shimmer {
    margin-bottom: 10px;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 1rem;
}

.layoutAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
