@mixin system-alert-elements($bg-color, $color) {
  background-color: $bg-color;
  border: 1px solid $color;

  .icon {
    color: $color;
  }

  .text {
    color: $color;
  }

  .closeIcon {
    color: $color;
  }

  .progressBar {
    background-color: $color;
  }
}

.SystemAlert {
  --success-bg-color: var(--color-green-100);
  --success-color: var(--color-green-400);
  --warning-bg-color: var(--color-prime-100);
  --warning-color: var(--color-prime-400);
  --danger-bg-color: var(--color-red-100);
  --danger-color: var(--color-red-400);
  --info-bg-color: var(--color-alt-100);
  --info-color: var(--color-alt-400);

  position: relative;
  display: flex;
  border-radius: 0.8rem;
  padding: 1rem;
  overflow: hidden;
  align-items: center;

  &.pauseOnHover {
    &:hover {
      .progressBar {
        animation-play-state: paused;
      }
    }
  }

  .text {
    font-weight: var(--font-bold);
    font-size: var(--font-size-s);
    margin-top: 0.2rem;
  }

  .icon {
    position: relative;
    padding-right: 1.25rem;
    font-size: 1.5rem;
    display: inline-block;
  }

  .closeIcon {
    position: relative;
    padding: 0;
    margin-left: auto;
    margin-right: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .progressBar {
    position: absolute;
    bottom: 0;
    right: 0;
    min-height: 3px;
    pointer-events: none;
    width: 100%;
    transform-origin: 100% 100%;

    & :global {
      animation-name: shrink;
      animation-delay: 200ms;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
    }
  }

  &.success {
    background-color: var(--success-bg-color);
    border: 1px solid var(--success-color);

    .icon {
      color: var(--success-color);
    }

    .text {
      color: var(--success-color);
    }

    .closeIcon {
      color: var(--success-color);
    }

    .progressBar {
      background-color: var(--success-color);
    }
  }

  &.warning {
    background-color: var(--warning-bg-color);
    border: 1px solid var(--warning-color);

    .icon {
      color: var(--warning-color);
    }

    .text {
      color: var(--warning-color);
    }

    .closeIcon {
      color: var(--warning-color);
    }

    .progressBar {
      background-color: var(--warning-color);
    }
  }

  &.danger {
    background-color: var(--danger-bg-color);
    border: 1px solid var(--danger-color);

    .icon {
      color: var(--danger-color);
    }

    .text {
      color: var(--danger-color);
    }

    .closeIcon {
      color: var(--danger-color);
    }

    .progressBar {
      background-color: var(--danger-color);
    }
  }

  &.info {
    background-color: var(--info-bg-color);
    border: 1px solid var(--color-alt-400);

    .icon {
      color: var(--info-color);
    }

    .text {
      color: var(--info-color);
    }

    .closeIcon {
      color: var(--info-color);
    }

    .progressBar {
      background-color: var(--info-color);
    }
  }
}

@keyframes :global(shrink) {
  100% {
    transform: scaleX(0);
  }
}
