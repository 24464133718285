.pageWrapper {
  margin-top: 0;
}
.search {
  margin-bottom: 1rem;
}


.PageTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba(1, 10, 44, 0.07);
	padding-bottom: 20px;

  .title {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-light);
    color: #010a2c;
    margin-bottom: 0.625rem;
    margin-top: 2.5rem;
  }
	.subtitle,
	.counter {
		font-weight: 300;
		font-size: 36px;
		line-height: 50px;
	}

	.subtitle {
		color: rgba(1, 10, 44, 0.15);
	}

	.counter {
		color: rgba(1, 10, 44, 0.15);
		margin-left: 20px;
	}
}

.container {
  padding: 1.25rem;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.25rem;

  .sidebar {
    grid-column: span 3 / span 3;

    nav {
      top: 1.25rem;
      position: sticky;
      display: flex;
      flex-direction: column;
      gap: 2px;
      a {
        padding: 0.625rem 0.9375rem;
        background-color: var(--color-gray-50);
        border-radius: 6px;
        color: #010a2c;
        font-weight: var(--font-light);
        font-size: 1rem;
        text-decoration: none;
        &:hover,
        &:focus,
        &.active {
          background-color: var(--color-prime-100);
          font-weight: var(--font-medium);
          color: var(--color-prime-400);
        }
      }
    }
  }

  .content {
    grid-column: span 9 / span 9;
    padding-right: 8.75rem;

    @media screen and (max-width: 1024px) {
      padding-right: 4rem;
    }
  }
}

.sectionSubTitle {
  margin-left: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  color: #010a2c;
  opacity: 0.5;
  font-size: var(--font-size-s);
  line-height: var(--line-height-xs);
  font-weight: var(--font-normal);
  text-transform: uppercase;
}
.sortDropdown {
  justify-content: space-between;
  div[role='menu'] {
    width: auto;
  }
}

