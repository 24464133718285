.LogoWhite {
  font-size: var(--font-size-xxl);

  svg {
    transition: 0.2s ease;
  }

  &:hover {
    svg {
      // transform: scale(1.05);
    }
  }
}
