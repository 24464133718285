.pageWrapper {
  flex: 1 0 auto;
}

.pageTitle {
  padding: 1.25rem;
}

.pageContainer {
  display: flex;
}

.filterSidebarWrapper {
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  > div:last-child {
    position: sticky;
    top: 3rem;
    border: none;
  }
}

.pageContent {
  padding: 1.25rem;
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;

  .shimmer {
    margin-bottom: 10px;
  }
}

.filtersWrapper {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;

  .tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
      margin-right: 10px;
    }
  }
}

.pagerWrapper {
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formGroup {
  margin-top: 1rem;
}

.dragAndDrop {
  min-height: 10.625rem;
  border: 2px dashed rgba(1, 10, 44, 0.15);
  border-radius: 0.5rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.75rem;

  &.dragging {
    border-color: var(--color-prime-400);
  }

  h4 {
    font-weight: var(--font-normal);
    font-size: var(--font-size-l);
    line-height: var(--line-height-s);
    color: rgba(1, 10, 44, 0.4);
    margin: 1rem 0 0.625rem 0;

    span {
      color: var(--color-prime-400);
    }
  }

  h5 {
    text-transform: uppercase;
    color: #010a2c;
    opacity: 0.2;
    font-size: var(--font-size-s);
    font-weight: var(--font-normal);
    margin: 0;
  }
}

.filePreview {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 5rem;
    width: 12.5rem;
    object-fit: cover;
    border-radius: 6px;
  }
}

.logoSwitcher {
  margin-top: 1.5rem;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  margin-bottom: 1rem;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.sectionTitle {
  margin-bottom: 0.75rem;
}

.coverImageLabel {
  margin-top: 0;
  margin-bottom: 0.75rem;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.actionsWrapper {
  justify-content: space-between;
  display: flex;

  margin-block: 1rem;

  > div {
    display: flex;
    gap: 0.5rem;
  }
}
