.pageWrapper {
  margin-top: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg);
  padding: 1.25rem 1.25rem;

  nav {
    margin-left: -1.25rem;
    a {
      color: var(--color-gray-400);
      font-size: var(--font-size-xxl);
      line-height: 1;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      margin: 0 0.625rem;

      &:hover,
      &.active {
        background-color: var(--color-prime-100);
        color: var(--color-prime-400);
      }
    }
  }
}

.saveChanges {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem;
  border-top: solid 1px #010a2c1f;
  border-bottom: solid 1px #010a2c1f;

  .buttonGroup {
    display: flex;
    gap: 0.625rem;
  }
}

.container {
  padding: 1.25rem;
}

.filesTable {
  border-spacing: 0 2px;

  thead {
    th {
      color: #010a2c;
      opacity: 0.5;
      font-family: Ubuntu;
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size-s);
      line-height: var(--line-height-xs);
      font-weight: var(--font-normal);
      text-transform: uppercase;
      text-align: left;
      letter-spacing: 1px;

      padding: 0.5rem 1.625rem;
    }
  }

  tbody {
    tr {
      background-color: var(--color-gray-50);
      td {
        padding: 1.0625rem 1.625rem;
      }
    }
  }
}

.unsavedChanges {
  font-weight: var(--font-light);
  font-size: var(--font-size-s);
  line-height: var(--line-height-xs);
  color: #010a2c;
  display: flex;
  gap: 0.625rem;
  align-items: center;
}

.dropD {
  
 
  max-width: 100%;
  width: 300px;
  max-height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
  // margin-top: -40px;

}

.errorAlert{  
  padding:1rem;
  background-color: #efcfcf;
  color: rgb(207, 90, 90);
  border: 1px solid #e5896a;
  border-radius: 0.375rem;
  font-size: medium;
  font-weight: 500;    
}