.searchInput {
  flex: 1 0 auto;
  display: flex;

  div:first-child {
    flex: 1 0 auto;

    input[data-has-search-button='true'] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > button {
    background-color: var(--color-prime-400);
    color: white;
    border: 1px solid var(--color-gray-dark);
    border-top-right-radius: 4.5rem;
    border-bottom-right-radius: 4.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;
    padding-left: 1.5rem;

    cursor: pointer;

    &:hover {
      background-color: var(--color-prime-600);
    }

    &:active {
      background-color: var(--color-prime-400);
      box-shadow: 0 0 0.5rem var(--color-prime-400);
    }

    svg {
      font-size: 1.625rem;
    }
  }
}

.pageTitle {
  border-bottom: 0;
}
.foldableContainer {
  margin-bottom: 30px;

  h4 {
    font-size: 21px;
    display: flex;
    user-select: none;
    cursor: pointer;
    font-weight: 500;

    svg {
      font-size: 24px;
      margin-right: 10px;
      color: #2e53e3;
    }
  }

  .filterChildren {
    padding-left: 34px;

    > * {
      margin-bottom: 14px;
    }
  }
}

.buttons {
  padding-right: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1px;
  justify-content: space-between;
}
.statusDropdown {
  width: 250px;
}
.buttons Button {
  width: 150px;
  height: 40px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 20px;
}
.searchBtn {
  display: flex;
  border-radius: 20px;
}

.inputWrapper {
  padding: 0.45rem 1.5rem !important;
  font-size: 14px !important;
}

.stepsTabContainer {
  width: 100%;
  cursor: pointer;
}

.stepsTabWrapper {
  width: inherit;
  background-color: #f9f9f9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-inline: 20px;
}

.stepsTabName {
  display: flex;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 60px;
  border-radius: 10px;
  font-size: 14px;
  color: grey;
  font-weight: 400;
  flex-wrap: wrap;
}

.stepsStatus {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  padding: 5px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.stepsDetails {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 4;
}

.processDetailItem {
  color: gray;
  display: block;
  font-size: 13px;
  margin-block: 5px;
}

.processDetailItemFlex {
  color: gray;
  display: block;
  font-size: 13px;
  margin-top: 5px;
}

.processDetailText {
  color: #000;
  font-weight: 400;
}

.processDetailTextChip {
  color: #fff;
  font-weight: 500;
  background-color: #9e9e9e;
  padding: 5px;
  padding-inline: 10px;
  border-radius: 30px;
  font-size: 10px;
}

.divider {
  height: 30px;
  width: 1px;
  border: 1px solid lightgray;
  margin-inline: 5px;
}

.loadingHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.loading {
  display: inline-block;
}

.loading:after {
  content: ' .';
  color: #000;
  animation: dots 2s steps(5, end) infinite;
}
.timeInput input {
  border: 1px solid lightgray;
  border-radius: 4.5rem;
  padding: 0.75rem 1.5rem;
  transition: 0.3s;
  width: 250px;
  line-height: 1.5rem;
  position: relative;
}
@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #000;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
  }
}
