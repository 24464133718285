.formWrapper {
  min-height: 100vh;
  display: flex;

  .form {
    margin: auto;
    width: 100%;
    max-width: 460px;
    color: var(--color-gray-600);
    padding: 2rem 3rem;

    > div {
      margin-bottom: 1.5rem;
    }

    > p {
      margin-top: 2.5rem;
      text-align: center;
    }

    .buttonWrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 4rem;
    }

    .splitWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .splitter {
        display: flex;
        align-items: center;
        color: var(--color-gray-400);
        flex: 1;
        margin: 0 1rem;

        span {
          flex: 1;
          height: 1px;
          background-color: var(--color-gray-200);
        }

        strong {
          flex: 0;
          padding: 0 1rem;
        }
      }
    }
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.termsAndPolicyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-size: var(--font-size-s);
}
