.layout {
  .pageTitle {
    padding-top: 1.25rem;
    padding-inline: 1.25rem;
  }

  .sticky {
    position: sticky;
    top: -20px;
    z-index: 10;
    background-color: #fff;
  }
}
