.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid rgba(1, 10, 44, 0.12);
  // margin-top: 1.5rem;
  font-size: var(--font-size-s);

  span {
    font-weight: 300;
    line-height: 22px;
    color: #010a2c;
    opacity: 0.5;
    margin-right: 10px;
  }

  * {
    font-size: var(--font-size-s);
  }
}
