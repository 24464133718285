.notifications {
  position: relative;

  > button {
    padding: 0.5rem 0.85rem;
    background: transparent !important;
    border: none !important;
    cursor: pointer;

    svg {
      margin-left: 0 !important;
    }

    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: white;
      border-radius: 50%;
      position: absolute;
      /* top: 0.2rem; */
      /* right: 0.65rem; */
      bottom: 10px;
      left: 5px;
    }
  }

  .notificationsDropdown {
    position: absolute;
    min-width: 25rem;
    right: 0;
    background-color: white;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    box-shadow: var(--shadow-2);
    max-height: 25rem;
    overflow-y: auto;

    .notification {
      padding-block: 0.75rem;
      padding-inline: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: var(--color-gray-50);
      }

      p {
        font-size: 1rem;

        span {
          font-size: 14px;
          margin-left: 0.5rem;
          opacity: 0.3;
        }
      }

      .newNotification {
        width: 0.625rem;
        height: 0.625rem;
        background-color: var(--color-prime-300);
        border-radius: 1rem;
        flex: 0 0 auto;
      }
    }
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 0.25;
  text-transform: uppercase;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
  opacity: 0.3;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.emptyNotifications {
  text-align: center;
  opacity: 0.3;
}

.clearNotifications {
  display: flex;
  justify-content: flex-end;
  button {
    font-size: 0.875rem;
    cursor: pointer;
    margin-right: 0.5rem;
    border: none;
    background-color: var(--color-prime-400);
    color: white;
    border-radius: 6px;
    padding-block: 0.25rem;
  }
}
