.Textarea {
  --color-error-light: var(--color-red-100);
  --color-error-dark: var(--color-red-400);
  --color-gray-dark: var(--color-gray-200);

  textarea {
    margin-top: 1.125rem;
    padding: 0.5625rem 1rem;
    border-radius: 0.375rem;
    border-color: rgba(1, 10, 44, 0.1);
    color: var(--color-gray-600);
    line-height: 1.5rem;
    font-size: var(--font-size-l);
    width: 100%;

    resize: none;

    &::placeholder {
      font-style: italic;
    }

    &:focus-visible {
      outline: none;
      box-shadow: var(--shadow-1);
    }

    &[data-has-error='true'] {
      box-shadow: 0px 0px 4px var(--color-error-dark);
      background-color: var(--color-error-light);
      border-color: var(--color-error-dark);

      &::placeholder {
        color: var(--color-error-dark);
      }
    }

    &:disabled {
      background-color: white;
      color: var(--color-gray-dark);
      font-weight: var(--font-light);
      border-color: #f6f6f3;

      &::placeholder {
        color: var(--color-gray-dark);
        font-weight: var(--font-light);
      }
    }
  }

  .error {
    color: var(--color-red-400);
    font-size: var(--font-size-s);
  }
}
